/* tslint:disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v2.0.14
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
export interface ITAppContactLiteArgs {
    fields: Array<TAppContactField>;
    userId?: string;
    contactId: string;
}
export class TAppContactLite {
    public fields: Array<TAppContactField>;
    public userId?: string;
    public contactId: string;
    constructor(args: ITAppContactLiteArgs) {
        if (args != null && args.fields != null) {
            this.fields = args.fields;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[fields] is unset!");
        }
        if (args != null && args.userId != null) {
            this.userId = args.userId;
        }
        if (args != null && args.contactId != null) {
            this.contactId = args.contactId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[contactId] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppContactLite");
        if (this.fields != null) {
            output.writeFieldBegin("fields", thrift.Thrift.Type.LIST, 1);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.fields.length);
            this.fields.forEach((value_1: TAppContactField): void => {
                value_1.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.userId != null) {
            output.writeFieldBegin("userId", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.userId);
            output.writeFieldEnd();
        }
        if (this.contactId != null) {
            output.writeFieldBegin("contactId", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.contactId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppContactLite {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_2: Array<TAppContactField> = new Array<TAppContactField>();
                        const metadata_1: thrift.TList = input.readListBegin();
                        const size_1: number = metadata_1.size;
                        for (let i_1: number = 0; i_1 < size_1; i_1++) {
                            const value_3: TAppContactField = TAppContactField.read(input);
                            value_2.push(value_3);
                        }
                        input.readListEnd();
                        _args.fields = value_2;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_4: string = input.readString();
                        _args.userId = value_4;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_5: string = input.readString();
                        _args.contactId = value_5;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.fields !== undefined && _args.contactId !== undefined) {
            return new TAppContactLite(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppContactLite from input");
        }
    }
}
export interface ITAppNameLiteArgs {
    fullName: string;
    firstName?: string;
    lastName?: string;
}
export class TAppNameLite {
    public fullName: string;
    public firstName?: string;
    public lastName?: string;
    constructor(args: ITAppNameLiteArgs) {
        if (args != null && args.fullName != null) {
            this.fullName = args.fullName;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[fullName] is unset!");
        }
        if (args != null && args.firstName != null) {
            this.firstName = args.firstName;
        }
        if (args != null && args.lastName != null) {
            this.lastName = args.lastName;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppNameLite");
        if (this.fullName != null) {
            output.writeFieldBegin("fullName", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.fullName);
            output.writeFieldEnd();
        }
        if (this.firstName != null) {
            output.writeFieldBegin("firstName", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.firstName);
            output.writeFieldEnd();
        }
        if (this.lastName != null) {
            output.writeFieldBegin("lastName", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.lastName);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppNameLite {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_6: string = input.readString();
                        _args.fullName = value_6;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_7: string = input.readString();
                        _args.firstName = value_7;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_8: string = input.readString();
                        _args.lastName = value_8;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.fullName !== undefined) {
            return new TAppNameLite(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppNameLite from input");
        }
    }
}
export interface ITAppPhoneLiteArgs {
    phoneNumber: string;
}
export class TAppPhoneLite {
    public phoneNumber: string;
    constructor(args: ITAppPhoneLiteArgs) {
        if (args != null && args.phoneNumber != null) {
            this.phoneNumber = args.phoneNumber;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[phoneNumber] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppPhoneLite");
        if (this.phoneNumber != null) {
            output.writeFieldBegin("phoneNumber", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.phoneNumber);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppPhoneLite {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_9: string = input.readString();
                        _args.phoneNumber = value_9;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.phoneNumber !== undefined) {
            return new TAppPhoneLite(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppPhoneLite from input");
        }
    }
}
export interface ITAppEmailLiteArgs {
    emailAddress: string;
    displayEmail?: string;
}
export class TAppEmailLite {
    public emailAddress: string;
    public displayEmail?: string;
    constructor(args: ITAppEmailLiteArgs) {
        if (args != null && args.emailAddress != null) {
            this.emailAddress = args.emailAddress;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[emailAddress] is unset!");
        }
        if (args != null && args.displayEmail != null) {
            this.displayEmail = args.displayEmail;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppEmailLite");
        if (this.emailAddress != null) {
            output.writeFieldBegin("emailAddress", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.emailAddress);
            output.writeFieldEnd();
        }
        if (this.displayEmail != null) {
            output.writeFieldBegin("displayEmail", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.displayEmail);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppEmailLite {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_10: string = input.readString();
                        _args.emailAddress = value_10;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_11: string = input.readString();
                        _args.displayEmail = value_11;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.emailAddress !== undefined) {
            return new TAppEmailLite(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppEmailLite from input");
        }
    }
}
export interface ITPushContactsLiteRequestArgs {
    contacts: Array<TAppContactLite>;
    hasMore: boolean;
}
export class TPushContactsLiteRequest {
    public contacts: Array<TAppContactLite>;
    public hasMore: boolean = false;
    constructor(args: ITPushContactsLiteRequestArgs) {
        if (args != null && args.contacts != null) {
            this.contacts = args.contacts;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[contacts] is unset!");
        }
        if (args != null && args.hasMore != null) {
            this.hasMore = args.hasMore;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[hasMore] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TPushContactsLiteRequest");
        if (this.contacts != null) {
            output.writeFieldBegin("contacts", thrift.Thrift.Type.LIST, 1);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.contacts.length);
            this.contacts.forEach((value_12: TAppContactLite): void => {
                value_12.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.hasMore != null) {
            output.writeFieldBegin("hasMore", thrift.Thrift.Type.BOOL, 2);
            output.writeBool(this.hasMore);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TPushContactsLiteRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_13: Array<TAppContactLite> = new Array<TAppContactLite>();
                        const metadata_2: thrift.TList = input.readListBegin();
                        const size_2: number = metadata_2.size;
                        for (let i_2: number = 0; i_2 < size_2; i_2++) {
                            const value_14: TAppContactLite = TAppContactLite.read(input);
                            value_13.push(value_14);
                        }
                        input.readListEnd();
                        _args.contacts = value_13;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_15: boolean = input.readBool();
                        _args.hasMore = value_15;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.contacts !== undefined && _args.hasMore !== undefined) {
            return new TPushContactsLiteRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TPushContactsLiteRequest from input");
        }
    }
}
export interface ITPushContactsLiteResponseArgs {
    shineUserContactIdMap: Map<string, Array<string>>;
    shineUserContactIds: Array<string>;
}
export class TPushContactsLiteResponse {
    public shineUserContactIdMap: Map<string, Array<string>>;
    public shineUserContactIds: Array<string>;
    constructor(args: ITPushContactsLiteResponseArgs) {
        if (args != null && args.shineUserContactIdMap != null) {
            this.shineUserContactIdMap = args.shineUserContactIdMap;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[shineUserContactIdMap] is unset!");
        }
        if (args != null && args.shineUserContactIds != null) {
            this.shineUserContactIds = args.shineUserContactIds;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[shineUserContactIds] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TPushContactsLiteResponse");
        if (this.shineUserContactIdMap != null) {
            output.writeFieldBegin("shineUserContactIdMap", thrift.Thrift.Type.MAP, 2);
            output.writeMapBegin(thrift.Thrift.Type.STRING, thrift.Thrift.Type.LIST, this.shineUserContactIdMap.size);
            this.shineUserContactIdMap.forEach((value_16: Array<string>, key_1: string): void => {
                output.writeString(key_1);
                output.writeListBegin(thrift.Thrift.Type.STRING, value_16.length);
                value_16.forEach((value_17: string): void => {
                    output.writeString(value_17);
                });
                output.writeListEnd();
            });
            output.writeMapEnd();
            output.writeFieldEnd();
        }
        if (this.shineUserContactIds != null) {
            output.writeFieldBegin("shineUserContactIds", thrift.Thrift.Type.LIST, 1);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.shineUserContactIds.length);
            this.shineUserContactIds.forEach((value_18: string): void => {
                output.writeString(value_18);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TPushContactsLiteResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 2:
                    if (fieldType === thrift.Thrift.Type.MAP) {
                        const value_19: Map<string, Array<string>> = new Map<string, Array<string>>();
                        const metadata_3: thrift.TMap = input.readMapBegin();
                        const size_3: number = metadata_3.size;
                        for (let i_3: number = 0; i_3 < size_3; i_3++) {
                            const key_2: string = input.readString();
                            const value_20: Array<string> = new Array<string>();
                            const metadata_4: thrift.TList = input.readListBegin();
                            const size_4: number = metadata_4.size;
                            for (let i_4: number = 0; i_4 < size_4; i_4++) {
                                const value_21: string = input.readString();
                                value_20.push(value_21);
                            }
                            input.readListEnd();
                            value_19.set(key_2, value_20);
                        }
                        input.readMapEnd();
                        _args.shineUserContactIdMap = value_19;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_22: Array<string> = new Array<string>();
                        const metadata_5: thrift.TList = input.readListBegin();
                        const size_5: number = metadata_5.size;
                        for (let i_5: number = 0; i_5 < size_5; i_5++) {
                            const value_23: string = input.readString();
                            value_22.push(value_23);
                        }
                        input.readListEnd();
                        _args.shineUserContactIds = value_22;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.shineUserContactIdMap !== undefined && _args.shineUserContactIds !== undefined) {
            return new TPushContactsLiteResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TPushContactsLiteResponse from input");
        }
    }
}
export interface ITAppContactFieldArgs {
    name?: TAppNameLite;
    phone?: TAppPhoneLite;
    email?: TAppEmailLite;
    photo?: TAppPhotoLite;
}
export class TAppContactField {
    public name?: TAppNameLite;
    public phone?: TAppPhoneLite;
    public email?: TAppEmailLite;
    public photo?: TAppPhotoLite;
    constructor(args?: ITAppContactFieldArgs) {
        let _fieldsSet: number = 0;
        if (args != null) {
            if (args.name != null) {
                _fieldsSet++;
                this.name = args.name;
            }
            if (args.phone != null) {
                _fieldsSet++;
                this.phone = args.phone;
            }
            if (args.email != null) {
                _fieldsSet++;
                this.email = args.email;
            }
            if (args.photo != null) {
                _fieldsSet++;
                this.photo = args.photo;
            }
            if (_fieldsSet > 1) {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with more than one set value!");
            }
            else if (_fieldsSet < 1) {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with no set value!");
            }
        }
    }
    public static fromName(name: TAppNameLite): TAppContactField {
        return new TAppContactField({ name });
    }
    public static fromPhone(phone: TAppPhoneLite): TAppContactField {
        return new TAppContactField({ phone });
    }
    public static fromEmail(email: TAppEmailLite): TAppContactField {
        return new TAppContactField({ email });
    }
    public static fromPhoto(photo: TAppPhotoLite): TAppContactField {
        return new TAppContactField({ photo });
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppContactField");
        if (this.name != null) {
            output.writeFieldBegin("name", thrift.Thrift.Type.STRUCT, 1);
            this.name.write(output);
            output.writeFieldEnd();
        }
        if (this.phone != null) {
            output.writeFieldBegin("phone", thrift.Thrift.Type.STRUCT, 2);
            this.phone.write(output);
            output.writeFieldEnd();
        }
        if (this.email != null) {
            output.writeFieldBegin("email", thrift.Thrift.Type.STRUCT, 3);
            this.email.write(output);
            output.writeFieldEnd();
        }
        if (this.photo != null) {
            output.writeFieldBegin("photo", thrift.Thrift.Type.STRUCT, 4);
            this.photo.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppContactField {
        let _fieldsSet: number = 0;
        let _returnValue: TAppContactField | null = null;
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        _fieldsSet++;
                        const value_24: TAppNameLite = TAppNameLite.read(input);
                        _returnValue = TAppContactField.fromName(value_24);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        _fieldsSet++;
                        const value_25: TAppPhoneLite = TAppPhoneLite.read(input);
                        _returnValue = TAppContactField.fromPhone(value_25);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        _fieldsSet++;
                        const value_26: TAppEmailLite = TAppEmailLite.read(input);
                        _returnValue = TAppContactField.fromEmail(value_26);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        _fieldsSet++;
                        const value_27: TAppPhotoLite = TAppPhotoLite.read(input);
                        _returnValue = TAppContactField.fromPhoto(value_27);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_fieldsSet > 1) {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with more than one set value!");
        }
        else if (_fieldsSet < 1) {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with no set value!");
        }
        if (_returnValue !== null) {
            return _returnValue;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read data for TUnion");
        }
    }
}
export interface ITAppPhotoLiteArgs {
    photoData?: Buffer;
    photoUrl?: string;
}
export class TAppPhotoLite {
    public photoData?: Buffer;
    public photoUrl?: string;
    constructor(args?: ITAppPhotoLiteArgs) {
        let _fieldsSet: number = 0;
        if (args != null) {
            if (args.photoData != null) {
                _fieldsSet++;
                this.photoData = args.photoData;
            }
            if (args.photoUrl != null) {
                _fieldsSet++;
                this.photoUrl = args.photoUrl;
            }
            if (_fieldsSet > 1) {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with more than one set value!");
            }
            else if (_fieldsSet < 1) {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with no set value!");
            }
        }
    }
    public static fromPhotoData(photoData: Buffer): TAppPhotoLite {
        return new TAppPhotoLite({ photoData });
    }
    public static fromPhotoUrl(photoUrl: string): TAppPhotoLite {
        return new TAppPhotoLite({ photoUrl });
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppPhotoLite");
        if (this.photoData != null) {
            output.writeFieldBegin("photoData", thrift.Thrift.Type.STRING, 1);
            output.writeBinary(this.photoData);
            output.writeFieldEnd();
        }
        if (this.photoUrl != null) {
            output.writeFieldBegin("photoUrl", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.photoUrl);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppPhotoLite {
        let _fieldsSet: number = 0;
        let _returnValue: TAppPhotoLite | null = null;
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        _fieldsSet++;
                        const value_28: Buffer = input.readBinary();
                        _returnValue = TAppPhotoLite.fromPhotoData(value_28);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        _fieldsSet++;
                        const value_29: string = input.readString();
                        _returnValue = TAppPhotoLite.fromPhotoUrl(value_29);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_fieldsSet > 1) {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with more than one set value!");
        }
        else if (_fieldsSet < 1) {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with no set value!");
        }
        if (_returnValue !== null) {
            return _returnValue;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read data for TUnion");
        }
    }
}
