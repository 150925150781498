/* tslint:disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v2.0.14
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/

import * as thrift from "thrift";
import { Int64 } from "thrift";
import * as PContact from "./../../common/models";
import * as PHeader from "./../../common/models";
import * as VerifyAppVersion from "./../../appversion";
import * as Phoenix from "./../../prototypes/phoenix/messages";
import * as AuthenticateCommon from "./.";
export enum TWebAuthRequestStatus {
    PENDING = 0,
    SUCCESS = 1,
    REJECTED = 2
}
export interface ITAuthenticateRequestArgs {
    deviceId: string;
    verifiedPhoneNumber?: string;
    firebaseIDToken: string;
    timestamp: number | Int64;
    isTestUser?: boolean;
    product?: PHeader.TAppProduct;
    firebaseUserIdFromKeychain?: string;
    platform?: VerifyAppVersion.TAppPlatform;
    verifiedEmailAddress?: string;
}
export class TAuthenticateRequest {
    public deviceId: string;
    public verifiedPhoneNumber?: string;
    public firebaseIDToken: string;
    public timestamp: Int64;
    public isTestUser?: boolean;
    public product?: PHeader.TAppProduct;
    public firebaseUserIdFromKeychain?: string;
    public platform?: VerifyAppVersion.TAppPlatform;
    public verifiedEmailAddress?: string;
    constructor(args: ITAuthenticateRequestArgs) {
        if (args != null && args.deviceId != null) {
            this.deviceId = args.deviceId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[deviceId] is unset!");
        }
        if (args != null && args.verifiedPhoneNumber != null) {
            this.verifiedPhoneNumber = args.verifiedPhoneNumber;
        }
        if (args != null && args.firebaseIDToken != null) {
            this.firebaseIDToken = args.firebaseIDToken;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[firebaseIDToken] is unset!");
        }
        if (args != null && args.timestamp != null) {
            if (typeof args.timestamp === "number") {
                this.timestamp = new Int64(args.timestamp);
            }
            else {
                this.timestamp = args.timestamp;
            }
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[timestamp] is unset!");
        }
        if (args != null && args.isTestUser != null) {
            this.isTestUser = args.isTestUser;
        }
        if (args != null && args.product != null) {
            this.product = args.product;
        }
        if (args != null && args.firebaseUserIdFromKeychain != null) {
            this.firebaseUserIdFromKeychain = args.firebaseUserIdFromKeychain;
        }
        if (args != null && args.platform != null) {
            this.platform = args.platform;
        }
        if (args != null && args.verifiedEmailAddress != null) {
            this.verifiedEmailAddress = args.verifiedEmailAddress;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAuthenticateRequest");
        if (this.deviceId != null) {
            output.writeFieldBegin("deviceId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.deviceId);
            output.writeFieldEnd();
        }
        if (this.verifiedPhoneNumber != null) {
            output.writeFieldBegin("verifiedPhoneNumber", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.verifiedPhoneNumber);
            output.writeFieldEnd();
        }
        if (this.firebaseIDToken != null) {
            output.writeFieldBegin("firebaseIDToken", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.firebaseIDToken);
            output.writeFieldEnd();
        }
        if (this.timestamp != null) {
            output.writeFieldBegin("timestamp", thrift.Thrift.Type.I64, 4);
            output.writeI64(this.timestamp);
            output.writeFieldEnd();
        }
        if (this.isTestUser != null) {
            output.writeFieldBegin("isTestUser", thrift.Thrift.Type.BOOL, 5);
            output.writeBool(this.isTestUser);
            output.writeFieldEnd();
        }
        if (this.product != null) {
            output.writeFieldBegin("product", thrift.Thrift.Type.I32, 6);
            output.writeI32(this.product);
            output.writeFieldEnd();
        }
        if (this.firebaseUserIdFromKeychain != null) {
            output.writeFieldBegin("firebaseUserIdFromKeychain", thrift.Thrift.Type.STRING, 7);
            output.writeString(this.firebaseUserIdFromKeychain);
            output.writeFieldEnd();
        }
        if (this.platform != null) {
            output.writeFieldBegin("platform", thrift.Thrift.Type.I32, 8);
            output.writeI32(this.platform);
            output.writeFieldEnd();
        }
        if (this.verifiedEmailAddress != null) {
            output.writeFieldBegin("verifiedEmailAddress", thrift.Thrift.Type.STRING, 9);
            output.writeString(this.verifiedEmailAddress);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAuthenticateRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_1: string = input.readString();
                        _args.deviceId = value_1;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_2: string = input.readString();
                        _args.verifiedPhoneNumber = value_2;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_3: string = input.readString();
                        _args.firebaseIDToken = value_3;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_4: Int64 = input.readI64();
                        _args.timestamp = value_4;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_5: boolean = input.readBool();
                        _args.isTestUser = value_5;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_6: PHeader.TAppProduct = input.readI32();
                        _args.product = value_6;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 7:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_7: string = input.readString();
                        _args.firebaseUserIdFromKeychain = value_7;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 8:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_8: VerifyAppVersion.TAppPlatform = input.readI32();
                        _args.platform = value_8;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 9:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_9: string = input.readString();
                        _args.verifiedEmailAddress = value_9;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.deviceId !== undefined && _args.firebaseIDToken !== undefined && _args.timestamp !== undefined) {
            return new TAuthenticateRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAuthenticateRequest from input");
        }
    }
}
export interface ITAuthenticateResponseArgs {
    userId: string;
    contact: PContact.TAppContact;
    refreshToken: string;
    accessToken: string;
    expiresAfter: number | Int64;
    customFirebaseToken: string;
    isReturningUser: boolean;
    hasImportedAccounts: boolean;
    verifiedPhone?: string;
    verifiedEmail?: string;
}
export class TAuthenticateResponse {
    public userId: string;
    public contact: PContact.TAppContact;
    public refreshToken: string;
    public accessToken: string;
    public expiresAfter: Int64;
    public customFirebaseToken: string;
    public isReturningUser: boolean = false;
    public hasImportedAccounts: boolean = false;
    public verifiedPhone?: string;
    public verifiedEmail?: string;
    constructor(args: ITAuthenticateResponseArgs) {
        if (args != null && args.userId != null) {
            this.userId = args.userId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[userId] is unset!");
        }
        if (args != null && args.contact != null) {
            this.contact = args.contact;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[contact] is unset!");
        }
        if (args != null && args.refreshToken != null) {
            this.refreshToken = args.refreshToken;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[refreshToken] is unset!");
        }
        if (args != null && args.accessToken != null) {
            this.accessToken = args.accessToken;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[accessToken] is unset!");
        }
        if (args != null && args.expiresAfter != null) {
            if (typeof args.expiresAfter === "number") {
                this.expiresAfter = new Int64(args.expiresAfter);
            }
            else {
                this.expiresAfter = args.expiresAfter;
            }
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[expiresAfter] is unset!");
        }
        if (args != null && args.customFirebaseToken != null) {
            this.customFirebaseToken = args.customFirebaseToken;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[customFirebaseToken] is unset!");
        }
        if (args != null && args.isReturningUser != null) {
            this.isReturningUser = args.isReturningUser;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[isReturningUser] is unset!");
        }
        if (args != null && args.hasImportedAccounts != null) {
            this.hasImportedAccounts = args.hasImportedAccounts;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[hasImportedAccounts] is unset!");
        }
        if (args != null && args.verifiedPhone != null) {
            this.verifiedPhone = args.verifiedPhone;
        }
        if (args != null && args.verifiedEmail != null) {
            this.verifiedEmail = args.verifiedEmail;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAuthenticateResponse");
        if (this.userId != null) {
            output.writeFieldBegin("userId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.userId);
            output.writeFieldEnd();
        }
        if (this.contact != null) {
            output.writeFieldBegin("contact", thrift.Thrift.Type.STRUCT, 2);
            this.contact.write(output);
            output.writeFieldEnd();
        }
        if (this.refreshToken != null) {
            output.writeFieldBegin("refreshToken", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.refreshToken);
            output.writeFieldEnd();
        }
        if (this.accessToken != null) {
            output.writeFieldBegin("accessToken", thrift.Thrift.Type.STRING, 4);
            output.writeString(this.accessToken);
            output.writeFieldEnd();
        }
        if (this.expiresAfter != null) {
            output.writeFieldBegin("expiresAfter", thrift.Thrift.Type.I64, 5);
            output.writeI64(this.expiresAfter);
            output.writeFieldEnd();
        }
        if (this.customFirebaseToken != null) {
            output.writeFieldBegin("customFirebaseToken", thrift.Thrift.Type.STRING, 6);
            output.writeString(this.customFirebaseToken);
            output.writeFieldEnd();
        }
        if (this.isReturningUser != null) {
            output.writeFieldBegin("isReturningUser", thrift.Thrift.Type.BOOL, 7);
            output.writeBool(this.isReturningUser);
            output.writeFieldEnd();
        }
        if (this.hasImportedAccounts != null) {
            output.writeFieldBegin("hasImportedAccounts", thrift.Thrift.Type.BOOL, 8);
            output.writeBool(this.hasImportedAccounts);
            output.writeFieldEnd();
        }
        if (this.verifiedPhone != null) {
            output.writeFieldBegin("verifiedPhone", thrift.Thrift.Type.STRING, 9);
            output.writeString(this.verifiedPhone);
            output.writeFieldEnd();
        }
        if (this.verifiedEmail != null) {
            output.writeFieldBegin("verifiedEmail", thrift.Thrift.Type.STRING, 11);
            output.writeString(this.verifiedEmail);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAuthenticateResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_10: string = input.readString();
                        _args.userId = value_10;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_11: PContact.TAppContact = PContact.TAppContact.read(input);
                        _args.contact = value_11;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_12: string = input.readString();
                        _args.refreshToken = value_12;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_13: string = input.readString();
                        _args.accessToken = value_13;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_14: Int64 = input.readI64();
                        _args.expiresAfter = value_14;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_15: string = input.readString();
                        _args.customFirebaseToken = value_15;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 7:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_16: boolean = input.readBool();
                        _args.isReturningUser = value_16;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 8:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_17: boolean = input.readBool();
                        _args.hasImportedAccounts = value_17;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 9:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_18: string = input.readString();
                        _args.verifiedPhone = value_18;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 11:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_19: string = input.readString();
                        _args.verifiedEmail = value_19;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.userId !== undefined && _args.contact !== undefined && _args.refreshToken !== undefined && _args.accessToken !== undefined && _args.expiresAfter !== undefined && _args.customFirebaseToken !== undefined && _args.isReturningUser !== undefined && _args.hasImportedAccounts !== undefined) {
            return new TAuthenticateResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAuthenticateResponse from input");
        }
    }
}
export interface ITCreateAndAuthenticateUserRequestArgs {
    deviceId: string;
    timestamp: number | Int64;
    isTestUser?: boolean;
    product?: PHeader.TAppProduct;
    firebaseUserIdFromKeychain?: string;
    firebaseTokenFromKeychain?: string;
    serverSecret?: string;
    captchaToken?: string;
    platform?: VerifyAppVersion.TAppPlatform;
}
export class TCreateAndAuthenticateUserRequest {
    public deviceId: string;
    public timestamp: Int64;
    public isTestUser?: boolean;
    public product?: PHeader.TAppProduct;
    public firebaseUserIdFromKeychain?: string;
    public firebaseTokenFromKeychain?: string;
    public serverSecret?: string;
    public captchaToken?: string;
    public platform?: VerifyAppVersion.TAppPlatform;
    constructor(args: ITCreateAndAuthenticateUserRequestArgs) {
        if (args != null && args.deviceId != null) {
            this.deviceId = args.deviceId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[deviceId] is unset!");
        }
        if (args != null && args.timestamp != null) {
            if (typeof args.timestamp === "number") {
                this.timestamp = new Int64(args.timestamp);
            }
            else {
                this.timestamp = args.timestamp;
            }
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[timestamp] is unset!");
        }
        if (args != null && args.isTestUser != null) {
            this.isTestUser = args.isTestUser;
        }
        if (args != null && args.product != null) {
            this.product = args.product;
        }
        if (args != null && args.firebaseUserIdFromKeychain != null) {
            this.firebaseUserIdFromKeychain = args.firebaseUserIdFromKeychain;
        }
        if (args != null && args.firebaseTokenFromKeychain != null) {
            this.firebaseTokenFromKeychain = args.firebaseTokenFromKeychain;
        }
        if (args != null && args.serverSecret != null) {
            this.serverSecret = args.serverSecret;
        }
        if (args != null && args.captchaToken != null) {
            this.captchaToken = args.captchaToken;
        }
        if (args != null && args.platform != null) {
            this.platform = args.platform;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TCreateAndAuthenticateUserRequest");
        if (this.deviceId != null) {
            output.writeFieldBegin("deviceId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.deviceId);
            output.writeFieldEnd();
        }
        if (this.timestamp != null) {
            output.writeFieldBegin("timestamp", thrift.Thrift.Type.I64, 2);
            output.writeI64(this.timestamp);
            output.writeFieldEnd();
        }
        if (this.isTestUser != null) {
            output.writeFieldBegin("isTestUser", thrift.Thrift.Type.BOOL, 3);
            output.writeBool(this.isTestUser);
            output.writeFieldEnd();
        }
        if (this.product != null) {
            output.writeFieldBegin("product", thrift.Thrift.Type.I32, 4);
            output.writeI32(this.product);
            output.writeFieldEnd();
        }
        if (this.firebaseUserIdFromKeychain != null) {
            output.writeFieldBegin("firebaseUserIdFromKeychain", thrift.Thrift.Type.STRING, 5);
            output.writeString(this.firebaseUserIdFromKeychain);
            output.writeFieldEnd();
        }
        if (this.firebaseTokenFromKeychain != null) {
            output.writeFieldBegin("firebaseTokenFromKeychain", thrift.Thrift.Type.STRING, 6);
            output.writeString(this.firebaseTokenFromKeychain);
            output.writeFieldEnd();
        }
        if (this.serverSecret != null) {
            output.writeFieldBegin("serverSecret", thrift.Thrift.Type.STRING, 7);
            output.writeString(this.serverSecret);
            output.writeFieldEnd();
        }
        if (this.captchaToken != null) {
            output.writeFieldBegin("captchaToken", thrift.Thrift.Type.STRING, 8);
            output.writeString(this.captchaToken);
            output.writeFieldEnd();
        }
        if (this.platform != null) {
            output.writeFieldBegin("platform", thrift.Thrift.Type.I32, 9);
            output.writeI32(this.platform);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TCreateAndAuthenticateUserRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_20: string = input.readString();
                        _args.deviceId = value_20;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_21: Int64 = input.readI64();
                        _args.timestamp = value_21;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_22: boolean = input.readBool();
                        _args.isTestUser = value_22;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_23: PHeader.TAppProduct = input.readI32();
                        _args.product = value_23;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_24: string = input.readString();
                        _args.firebaseUserIdFromKeychain = value_24;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_25: string = input.readString();
                        _args.firebaseTokenFromKeychain = value_25;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 7:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_26: string = input.readString();
                        _args.serverSecret = value_26;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 8:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_27: string = input.readString();
                        _args.captchaToken = value_27;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 9:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_28: VerifyAppVersion.TAppPlatform = input.readI32();
                        _args.platform = value_28;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.deviceId !== undefined && _args.timestamp !== undefined) {
            return new TCreateAndAuthenticateUserRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TCreateAndAuthenticateUserRequest from input");
        }
    }
}
export interface ITRefetchAccessTokenRequestArgs {
    userId: string;
    deviceId: string;
    refreshToken: string;
    timestamp: number | Int64;
    platform?: VerifyAppVersion.TAppPlatform;
    product?: PHeader.TAppProduct;
}
export class TRefetchAccessTokenRequest {
    public userId: string;
    public deviceId: string;
    public refreshToken: string;
    public timestamp: Int64;
    public platform?: VerifyAppVersion.TAppPlatform;
    public product?: PHeader.TAppProduct;
    constructor(args: ITRefetchAccessTokenRequestArgs) {
        if (args != null && args.userId != null) {
            this.userId = args.userId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[userId] is unset!");
        }
        if (args != null && args.deviceId != null) {
            this.deviceId = args.deviceId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[deviceId] is unset!");
        }
        if (args != null && args.refreshToken != null) {
            this.refreshToken = args.refreshToken;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[refreshToken] is unset!");
        }
        if (args != null && args.timestamp != null) {
            if (typeof args.timestamp === "number") {
                this.timestamp = new Int64(args.timestamp);
            }
            else {
                this.timestamp = args.timestamp;
            }
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[timestamp] is unset!");
        }
        if (args != null && args.platform != null) {
            this.platform = args.platform;
        }
        if (args != null && args.product != null) {
            this.product = args.product;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TRefetchAccessTokenRequest");
        if (this.userId != null) {
            output.writeFieldBegin("userId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.userId);
            output.writeFieldEnd();
        }
        if (this.deviceId != null) {
            output.writeFieldBegin("deviceId", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.deviceId);
            output.writeFieldEnd();
        }
        if (this.refreshToken != null) {
            output.writeFieldBegin("refreshToken", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.refreshToken);
            output.writeFieldEnd();
        }
        if (this.timestamp != null) {
            output.writeFieldBegin("timestamp", thrift.Thrift.Type.I64, 4);
            output.writeI64(this.timestamp);
            output.writeFieldEnd();
        }
        if (this.platform != null) {
            output.writeFieldBegin("platform", thrift.Thrift.Type.I32, 5);
            output.writeI32(this.platform);
            output.writeFieldEnd();
        }
        if (this.product != null) {
            output.writeFieldBegin("product", thrift.Thrift.Type.I32, 6);
            output.writeI32(this.product);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TRefetchAccessTokenRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_29: string = input.readString();
                        _args.userId = value_29;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_30: string = input.readString();
                        _args.deviceId = value_30;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_31: string = input.readString();
                        _args.refreshToken = value_31;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_32: Int64 = input.readI64();
                        _args.timestamp = value_32;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_33: VerifyAppVersion.TAppPlatform = input.readI32();
                        _args.platform = value_33;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_34: PHeader.TAppProduct = input.readI32();
                        _args.product = value_34;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.userId !== undefined && _args.deviceId !== undefined && _args.refreshToken !== undefined && _args.timestamp !== undefined) {
            return new TRefetchAccessTokenRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TRefetchAccessTokenRequest from input");
        }
    }
}
export interface ITRefetchAccessTokenResponseArgs {
    accessToken: string;
    expiresAfter: number | Int64;
    customFirebaseToken?: string;
    contact?: PContact.TAppContact;
    verifiedPhone?: string;
    verifiedEmail?: string;
}
export class TRefetchAccessTokenResponse {
    public accessToken: string;
    public expiresAfter: Int64;
    public customFirebaseToken?: string;
    public contact?: PContact.TAppContact;
    public verifiedPhone?: string;
    public verifiedEmail?: string;
    constructor(args: ITRefetchAccessTokenResponseArgs) {
        if (args != null && args.accessToken != null) {
            this.accessToken = args.accessToken;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[accessToken] is unset!");
        }
        if (args != null && args.expiresAfter != null) {
            if (typeof args.expiresAfter === "number") {
                this.expiresAfter = new Int64(args.expiresAfter);
            }
            else {
                this.expiresAfter = args.expiresAfter;
            }
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[expiresAfter] is unset!");
        }
        if (args != null && args.customFirebaseToken != null) {
            this.customFirebaseToken = args.customFirebaseToken;
        }
        if (args != null && args.contact != null) {
            this.contact = args.contact;
        }
        if (args != null && args.verifiedPhone != null) {
            this.verifiedPhone = args.verifiedPhone;
        }
        if (args != null && args.verifiedEmail != null) {
            this.verifiedEmail = args.verifiedEmail;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TRefetchAccessTokenResponse");
        if (this.accessToken != null) {
            output.writeFieldBegin("accessToken", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.accessToken);
            output.writeFieldEnd();
        }
        if (this.expiresAfter != null) {
            output.writeFieldBegin("expiresAfter", thrift.Thrift.Type.I64, 2);
            output.writeI64(this.expiresAfter);
            output.writeFieldEnd();
        }
        if (this.customFirebaseToken != null) {
            output.writeFieldBegin("customFirebaseToken", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.customFirebaseToken);
            output.writeFieldEnd();
        }
        if (this.contact != null) {
            output.writeFieldBegin("contact", thrift.Thrift.Type.STRUCT, 4);
            this.contact.write(output);
            output.writeFieldEnd();
        }
        if (this.verifiedPhone != null) {
            output.writeFieldBegin("verifiedPhone", thrift.Thrift.Type.STRING, 5);
            output.writeString(this.verifiedPhone);
            output.writeFieldEnd();
        }
        if (this.verifiedEmail != null) {
            output.writeFieldBegin("verifiedEmail", thrift.Thrift.Type.STRING, 6);
            output.writeString(this.verifiedEmail);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TRefetchAccessTokenResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_35: string = input.readString();
                        _args.accessToken = value_35;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_36: Int64 = input.readI64();
                        _args.expiresAfter = value_36;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_37: string = input.readString();
                        _args.customFirebaseToken = value_37;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_38: PContact.TAppContact = PContact.TAppContact.read(input);
                        _args.contact = value_38;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_39: string = input.readString();
                        _args.verifiedPhone = value_39;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 6:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_40: string = input.readString();
                        _args.verifiedEmail = value_40;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.accessToken !== undefined && _args.expiresAfter !== undefined) {
            return new TRefetchAccessTokenResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TRefetchAccessTokenResponse from input");
        }
    }
}
export interface ITAuthenticateEmailRequestArgs {
    email: string;
    timestamp: number | Int64;
    signature: string;
}
export class TAuthenticateEmailRequest {
    public email: string;
    public timestamp: Int64;
    public signature: string;
    constructor(args: ITAuthenticateEmailRequestArgs) {
        if (args != null && args.email != null) {
            this.email = args.email;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[email] is unset!");
        }
        if (args != null && args.timestamp != null) {
            if (typeof args.timestamp === "number") {
                this.timestamp = new Int64(args.timestamp);
            }
            else {
                this.timestamp = args.timestamp;
            }
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[timestamp] is unset!");
        }
        if (args != null && args.signature != null) {
            this.signature = args.signature;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[signature] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAuthenticateEmailRequest");
        if (this.email != null) {
            output.writeFieldBegin("email", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.email);
            output.writeFieldEnd();
        }
        if (this.timestamp != null) {
            output.writeFieldBegin("timestamp", thrift.Thrift.Type.I64, 2);
            output.writeI64(this.timestamp);
            output.writeFieldEnd();
        }
        if (this.signature != null) {
            output.writeFieldBegin("signature", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.signature);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAuthenticateEmailRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_41: string = input.readString();
                        _args.email = value_41;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_42: Int64 = input.readI64();
                        _args.timestamp = value_42;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_43: string = input.readString();
                        _args.signature = value_43;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.email !== undefined && _args.timestamp !== undefined && _args.signature !== undefined) {
            return new TAuthenticateEmailRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAuthenticateEmailRequest from input");
        }
    }
}
export interface ITAuthenticateEmailResponseArgs {
    userId: string;
    accessToken: string;
    expiresAfter: number | Int64;
    sendToDashboard?: boolean;
}
export class TAuthenticateEmailResponse {
    public userId: string;
    public accessToken: string;
    public expiresAfter: Int64;
    public sendToDashboard?: boolean;
    constructor(args: ITAuthenticateEmailResponseArgs) {
        if (args != null && args.userId != null) {
            this.userId = args.userId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[userId] is unset!");
        }
        if (args != null && args.accessToken != null) {
            this.accessToken = args.accessToken;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[accessToken] is unset!");
        }
        if (args != null && args.expiresAfter != null) {
            if (typeof args.expiresAfter === "number") {
                this.expiresAfter = new Int64(args.expiresAfter);
            }
            else {
                this.expiresAfter = args.expiresAfter;
            }
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[expiresAfter] is unset!");
        }
        if (args != null && args.sendToDashboard != null) {
            this.sendToDashboard = args.sendToDashboard;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAuthenticateEmailResponse");
        if (this.userId != null) {
            output.writeFieldBegin("userId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.userId);
            output.writeFieldEnd();
        }
        if (this.accessToken != null) {
            output.writeFieldBegin("accessToken", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.accessToken);
            output.writeFieldEnd();
        }
        if (this.expiresAfter != null) {
            output.writeFieldBegin("expiresAfter", thrift.Thrift.Type.I64, 3);
            output.writeI64(this.expiresAfter);
            output.writeFieldEnd();
        }
        if (this.sendToDashboard != null) {
            output.writeFieldBegin("sendToDashboard", thrift.Thrift.Type.BOOL, 4);
            output.writeBool(this.sendToDashboard);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAuthenticateEmailResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_44: string = input.readString();
                        _args.userId = value_44;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_45: string = input.readString();
                        _args.accessToken = value_45;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_46: Int64 = input.readI64();
                        _args.expiresAfter = value_46;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_47: boolean = input.readBool();
                        _args.sendToDashboard = value_47;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.userId !== undefined && _args.accessToken !== undefined && _args.expiresAfter !== undefined) {
            return new TAuthenticateEmailResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAuthenticateEmailResponse from input");
        }
    }
}
export interface ITSendPiiVerificationCodeRequestArgs {
    pii: AuthenticateCommon.TAppPii;
}
export class TSendPiiVerificationCodeRequest {
    public pii: AuthenticateCommon.TAppPii;
    constructor(args: ITSendPiiVerificationCodeRequestArgs) {
        if (args != null && args.pii != null) {
            this.pii = args.pii;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[pii] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TSendPiiVerificationCodeRequest");
        if (this.pii != null) {
            output.writeFieldBegin("pii", thrift.Thrift.Type.STRUCT, 1);
            this.pii.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TSendPiiVerificationCodeRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_48: AuthenticateCommon.TAppPii = AuthenticateCommon.TAppPii.read(input);
                        _args.pii = value_48;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.pii !== undefined) {
            return new TSendPiiVerificationCodeRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TSendPiiVerificationCodeRequest from input");
        }
    }
}
export interface ITSendPiiVerificationCodeResponseArgs {
    success: boolean;
    errorMessage?: string;
}
export class TSendPiiVerificationCodeResponse {
    public success: boolean;
    public errorMessage?: string;
    constructor(args: ITSendPiiVerificationCodeResponseArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[success] is unset!");
        }
        if (args != null && args.errorMessage != null) {
            this.errorMessage = args.errorMessage;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TSendPiiVerificationCodeResponse");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.BOOL, 1);
            output.writeBool(this.success);
            output.writeFieldEnd();
        }
        if (this.errorMessage != null) {
            output.writeFieldBegin("errorMessage", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.errorMessage);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TSendPiiVerificationCodeResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_49: boolean = input.readBool();
                        _args.success = value_49;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_50: string = input.readString();
                        _args.errorMessage = value_50;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.success !== undefined) {
            return new TSendPiiVerificationCodeResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TSendPiiVerificationCodeResponse from input");
        }
    }
}
export interface ITVerifyWithPiiCodeRequestArgs {
    pii: AuthenticateCommon.TAppPii;
    code: string;
}
export class TVerifyWithPiiCodeRequest {
    public pii: AuthenticateCommon.TAppPii;
    public code: string;
    constructor(args: ITVerifyWithPiiCodeRequestArgs) {
        if (args != null && args.pii != null) {
            this.pii = args.pii;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[pii] is unset!");
        }
        if (args != null && args.code != null) {
            this.code = args.code;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[code] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TVerifyWithPiiCodeRequest");
        if (this.pii != null) {
            output.writeFieldBegin("pii", thrift.Thrift.Type.STRUCT, 1);
            this.pii.write(output);
            output.writeFieldEnd();
        }
        if (this.code != null) {
            output.writeFieldBegin("code", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.code);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TVerifyWithPiiCodeRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_51: AuthenticateCommon.TAppPii = AuthenticateCommon.TAppPii.read(input);
                        _args.pii = value_51;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_52: string = input.readString();
                        _args.code = value_52;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.pii !== undefined && _args.code !== undefined) {
            return new TVerifyWithPiiCodeRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TVerifyWithPiiCodeRequest from input");
        }
    }
}
export interface ITAppPromptUserMergeArgs {
    pii: Array<AuthenticateCommon.TAppPii>;
}
export class TAppPromptUserMerge {
    public pii: Array<AuthenticateCommon.TAppPii>;
    constructor(args: ITAppPromptUserMergeArgs) {
        if (args != null && args.pii != null) {
            this.pii = args.pii;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[pii] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppPromptUserMerge");
        if (this.pii != null) {
            output.writeFieldBegin("pii", thrift.Thrift.Type.LIST, 1);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.pii.length);
            this.pii.forEach((value_53: AuthenticateCommon.TAppPii): void => {
                value_53.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppPromptUserMerge {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_54: Array<AuthenticateCommon.TAppPii> = new Array<AuthenticateCommon.TAppPii>();
                        const metadata_1: thrift.TList = input.readListBegin();
                        const size_1: number = metadata_1.size;
                        for (let i_1: number = 0; i_1 < size_1; i_1++) {
                            const value_55: AuthenticateCommon.TAppPii = AuthenticateCommon.TAppPii.read(input);
                            value_54.push(value_55);
                        }
                        input.readListEnd();
                        _args.pii = value_54;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.pii !== undefined) {
            return new TAppPromptUserMerge(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppPromptUserMerge from input");
        }
    }
}
export interface ITAppPiiCodeVerificationSuccessArgs {
    pii: Array<AuthenticateCommon.TAppPii>;
    success: boolean;
    errorMessage?: string;
}
export class TAppPiiCodeVerificationSuccess {
    public pii: Array<AuthenticateCommon.TAppPii>;
    public success: boolean;
    public errorMessage?: string;
    constructor(args: ITAppPiiCodeVerificationSuccessArgs) {
        if (args != null && args.pii != null) {
            this.pii = args.pii;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[pii] is unset!");
        }
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[success] is unset!");
        }
        if (args != null && args.errorMessage != null) {
            this.errorMessage = args.errorMessage;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppPiiCodeVerificationSuccess");
        if (this.pii != null) {
            output.writeFieldBegin("pii", thrift.Thrift.Type.LIST, 1);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.pii.length);
            this.pii.forEach((value_56: AuthenticateCommon.TAppPii): void => {
                value_56.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.BOOL, 2);
            output.writeBool(this.success);
            output.writeFieldEnd();
        }
        if (this.errorMessage != null) {
            output.writeFieldBegin("errorMessage", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.errorMessage);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppPiiCodeVerificationSuccess {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_57: Array<AuthenticateCommon.TAppPii> = new Array<AuthenticateCommon.TAppPii>();
                        const metadata_2: thrift.TList = input.readListBegin();
                        const size_2: number = metadata_2.size;
                        for (let i_2: number = 0; i_2 < size_2; i_2++) {
                            const value_58: AuthenticateCommon.TAppPii = AuthenticateCommon.TAppPii.read(input);
                            value_57.push(value_58);
                        }
                        input.readListEnd();
                        _args.pii = value_57;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_59: boolean = input.readBool();
                        _args.success = value_59;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_60: string = input.readString();
                        _args.errorMessage = value_60;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.pii !== undefined && _args.success !== undefined) {
            return new TAppPiiCodeVerificationSuccess(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAppPiiCodeVerificationSuccess from input");
        }
    }
}
export interface ITVerifyWithPiiCodeResponseArgs {
    response: TAppVerifyWithPiiCodeResponse;
}
export class TVerifyWithPiiCodeResponse {
    public response: TAppVerifyWithPiiCodeResponse;
    constructor(args: ITVerifyWithPiiCodeResponseArgs) {
        if (args != null && args.response != null) {
            this.response = args.response;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[response] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TVerifyWithPiiCodeResponse");
        if (this.response != null) {
            output.writeFieldBegin("response", thrift.Thrift.Type.STRUCT, 1);
            this.response.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TVerifyWithPiiCodeResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_61: TAppVerifyWithPiiCodeResponse = TAppVerifyWithPiiCodeResponse.read(input);
                        _args.response = value_61;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.response !== undefined) {
            return new TVerifyWithPiiCodeResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TVerifyWithPiiCodeResponse from input");
        }
    }
}
export interface ITAuthenticateRaterRequestArgs {
    timestamp: number | Int64;
    oauthToken: string;
}
export class TAuthenticateRaterRequest {
    public timestamp: Int64;
    public oauthToken: string;
    constructor(args: ITAuthenticateRaterRequestArgs) {
        if (args != null && args.timestamp != null) {
            if (typeof args.timestamp === "number") {
                this.timestamp = new Int64(args.timestamp);
            }
            else {
                this.timestamp = args.timestamp;
            }
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[timestamp] is unset!");
        }
        if (args != null && args.oauthToken != null) {
            this.oauthToken = args.oauthToken;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[oauthToken] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAuthenticateRaterRequest");
        if (this.timestamp != null) {
            output.writeFieldBegin("timestamp", thrift.Thrift.Type.I64, 1);
            output.writeI64(this.timestamp);
            output.writeFieldEnd();
        }
        if (this.oauthToken != null) {
            output.writeFieldBegin("oauthToken", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.oauthToken);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAuthenticateRaterRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_62: Int64 = input.readI64();
                        _args.timestamp = value_62;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_63: string = input.readString();
                        _args.oauthToken = value_63;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.timestamp !== undefined && _args.oauthToken !== undefined) {
            return new TAuthenticateRaterRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAuthenticateRaterRequest from input");
        }
    }
}
export interface ITAuthenticateRaterResponseArgs {
    raterId: string;
    accessToken: string;
    expiresAfter: number | Int64;
}
export class TAuthenticateRaterResponse {
    public raterId: string;
    public accessToken: string;
    public expiresAfter: Int64;
    constructor(args: ITAuthenticateRaterResponseArgs) {
        if (args != null && args.raterId != null) {
            this.raterId = args.raterId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[raterId] is unset!");
        }
        if (args != null && args.accessToken != null) {
            this.accessToken = args.accessToken;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[accessToken] is unset!");
        }
        if (args != null && args.expiresAfter != null) {
            if (typeof args.expiresAfter === "number") {
                this.expiresAfter = new Int64(args.expiresAfter);
            }
            else {
                this.expiresAfter = args.expiresAfter;
            }
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[expiresAfter] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAuthenticateRaterResponse");
        if (this.raterId != null) {
            output.writeFieldBegin("raterId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.raterId);
            output.writeFieldEnd();
        }
        if (this.accessToken != null) {
            output.writeFieldBegin("accessToken", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.accessToken);
            output.writeFieldEnd();
        }
        if (this.expiresAfter != null) {
            output.writeFieldBegin("expiresAfter", thrift.Thrift.Type.I64, 3);
            output.writeI64(this.expiresAfter);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAuthenticateRaterResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_64: string = input.readString();
                        _args.raterId = value_64;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_65: string = input.readString();
                        _args.accessToken = value_65;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_66: Int64 = input.readI64();
                        _args.expiresAfter = value_66;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.raterId !== undefined && _args.accessToken !== undefined && _args.expiresAfter !== undefined) {
            return new TAuthenticateRaterResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAuthenticateRaterResponse from input");
        }
    }
}
export interface ITSendEmailVerificationRequestArgs {
    email: string;
}
export class TSendEmailVerificationRequest {
    public email: string;
    constructor(args: ITSendEmailVerificationRequestArgs) {
        if (args != null && args.email != null) {
            this.email = args.email;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[email] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TSendEmailVerificationRequest");
        if (this.email != null) {
            output.writeFieldBegin("email", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.email);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TSendEmailVerificationRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_67: string = input.readString();
                        _args.email = value_67;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.email !== undefined) {
            return new TSendEmailVerificationRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TSendEmailVerificationRequest from input");
        }
    }
}
export interface ITSendEmailVerificationResponseArgs {
}
export class TSendEmailVerificationResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TSendEmailVerificationResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TSendEmailVerificationResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TSendEmailVerificationResponse();
    }
}
export interface ITObjectIdArgs {
    value?: string;
}
export class TObjectId {
    public value?: string;
    constructor(args?: ITObjectIdArgs) {
        if (args != null && args.value != null) {
            this.value = args.value;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TObjectId");
        if (this.value != null) {
            output.writeFieldBegin("value", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.value);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TObjectId {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_68: string = input.readString();
                        _args.value = value_68;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TObjectId(_args);
    }
}
export interface ITWebAuthenticateRequestArgs {
    phoneNumber: string;
    authRequestId: string;
    serverEditSeqId?: TObjectId;
    status?: TWebAuthRequestStatus;
}
export class TWebAuthenticateRequest {
    public phoneNumber: string;
    public authRequestId: string;
    public serverEditSeqId?: TObjectId;
    public status?: TWebAuthRequestStatus;
    constructor(args: ITWebAuthenticateRequestArgs) {
        if (args != null && args.phoneNumber != null) {
            this.phoneNumber = args.phoneNumber;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[phoneNumber] is unset!");
        }
        if (args != null && args.authRequestId != null) {
            this.authRequestId = args.authRequestId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[authRequestId] is unset!");
        }
        if (args != null && args.serverEditSeqId != null) {
            this.serverEditSeqId = args.serverEditSeqId;
        }
        if (args != null && args.status != null) {
            this.status = args.status;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TWebAuthenticateRequest");
        if (this.phoneNumber != null) {
            output.writeFieldBegin("phoneNumber", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.phoneNumber);
            output.writeFieldEnd();
        }
        if (this.authRequestId != null) {
            output.writeFieldBegin("authRequestId", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.authRequestId);
            output.writeFieldEnd();
        }
        if (this.serverEditSeqId != null) {
            output.writeFieldBegin("serverEditSeqId", thrift.Thrift.Type.STRUCT, 3);
            this.serverEditSeqId.write(output);
            output.writeFieldEnd();
        }
        if (this.status != null) {
            output.writeFieldBegin("status", thrift.Thrift.Type.I32, 4);
            output.writeI32(this.status);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TWebAuthenticateRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_69: string = input.readString();
                        _args.phoneNumber = value_69;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_70: string = input.readString();
                        _args.authRequestId = value_70;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_71: TObjectId = TObjectId.read(input);
                        _args.serverEditSeqId = value_71;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_72: TWebAuthRequestStatus = input.readI32();
                        _args.status = value_72;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.phoneNumber !== undefined && _args.authRequestId !== undefined) {
            return new TWebAuthenticateRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TWebAuthenticateRequest from input");
        }
    }
}
export interface ITActiveWebAuthResponseArgs {
    request?: TWebAuthenticateRequest;
}
export class TActiveWebAuthResponse {
    public request?: TWebAuthenticateRequest;
    constructor(args?: ITActiveWebAuthResponseArgs) {
        if (args != null && args.request != null) {
            this.request = args.request;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TActiveWebAuthResponse");
        if (this.request != null) {
            output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 1);
            this.request.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TActiveWebAuthResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_73: TWebAuthenticateRequest = TWebAuthenticateRequest.read(input);
                        _args.request = value_73;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TActiveWebAuthResponse(_args);
    }
}
export interface ITWebAuthenticateUpdateRequestArgs {
    authRequestId: string;
    status: TWebAuthRequestStatus;
}
export class TWebAuthenticateUpdateRequest {
    public authRequestId: string;
    public status: TWebAuthRequestStatus;
    constructor(args: ITWebAuthenticateUpdateRequestArgs) {
        if (args != null && args.authRequestId != null) {
            this.authRequestId = args.authRequestId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[authRequestId] is unset!");
        }
        if (args != null && args.status != null) {
            this.status = args.status;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[status] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TWebAuthenticateUpdateRequest");
        if (this.authRequestId != null) {
            output.writeFieldBegin("authRequestId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.authRequestId);
            output.writeFieldEnd();
        }
        if (this.status != null) {
            output.writeFieldBegin("status", thrift.Thrift.Type.I32, 2);
            output.writeI32(this.status);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TWebAuthenticateUpdateRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_74: string = input.readString();
                        _args.authRequestId = value_74;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_75: TWebAuthRequestStatus = input.readI32();
                        _args.status = value_75;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.authRequestId !== undefined && _args.status !== undefined) {
            return new TWebAuthenticateUpdateRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TWebAuthenticateUpdateRequest from input");
        }
    }
}
export interface ITWebAuthenticateUpdateResponseArgs {
}
export class TWebAuthenticateUpdateResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TWebAuthenticateUpdateResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TWebAuthenticateUpdateResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TWebAuthenticateUpdateResponse();
    }
}
export interface ITWebAuthenticateResponseArgs {
    userId: string;
    contactId: string;
    accessToken: string;
    customFirebaseToken: string;
}
export class TWebAuthenticateResponse {
    public userId: string;
    public contactId: string;
    public accessToken: string;
    public customFirebaseToken: string;
    constructor(args: ITWebAuthenticateResponseArgs) {
        if (args != null && args.userId != null) {
            this.userId = args.userId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[userId] is unset!");
        }
        if (args != null && args.contactId != null) {
            this.contactId = args.contactId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[contactId] is unset!");
        }
        if (args != null && args.accessToken != null) {
            this.accessToken = args.accessToken;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[accessToken] is unset!");
        }
        if (args != null && args.customFirebaseToken != null) {
            this.customFirebaseToken = args.customFirebaseToken;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[customFirebaseToken] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TWebAuthenticateResponse");
        if (this.userId != null) {
            output.writeFieldBegin("userId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.userId);
            output.writeFieldEnd();
        }
        if (this.contactId != null) {
            output.writeFieldBegin("contactId", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.contactId);
            output.writeFieldEnd();
        }
        if (this.accessToken != null) {
            output.writeFieldBegin("accessToken", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.accessToken);
            output.writeFieldEnd();
        }
        if (this.customFirebaseToken != null) {
            output.writeFieldBegin("customFirebaseToken", thrift.Thrift.Type.STRING, 4);
            output.writeString(this.customFirebaseToken);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TWebAuthenticateResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_76: string = input.readString();
                        _args.userId = value_76;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_77: string = input.readString();
                        _args.contactId = value_77;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_78: string = input.readString();
                        _args.accessToken = value_78;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_79: string = input.readString();
                        _args.customFirebaseToken = value_79;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.userId !== undefined && _args.contactId !== undefined && _args.accessToken !== undefined && _args.customFirebaseToken !== undefined) {
            return new TWebAuthenticateResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TWebAuthenticateResponse from input");
        }
    }
}
export interface ITWebAuthenticateStatusResponseArgs {
    status: TWebAuthRequestStatus;
    authResponse?: TWebAuthenticateResponse;
}
export class TWebAuthenticateStatusResponse {
    public status: TWebAuthRequestStatus;
    public authResponse?: TWebAuthenticateResponse;
    constructor(args: ITWebAuthenticateStatusResponseArgs) {
        if (args != null && args.status != null) {
            this.status = args.status;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[status] is unset!");
        }
        if (args != null && args.authResponse != null) {
            this.authResponse = args.authResponse;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TWebAuthenticateStatusResponse");
        if (this.status != null) {
            output.writeFieldBegin("status", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.status);
            output.writeFieldEnd();
        }
        if (this.authResponse != null) {
            output.writeFieldBegin("authResponse", thrift.Thrift.Type.STRUCT, 2);
            this.authResponse.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TWebAuthenticateStatusResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_80: TWebAuthRequestStatus = input.readI32();
                        _args.status = value_80;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_81: TWebAuthenticateResponse = TWebAuthenticateResponse.read(input);
                        _args.authResponse = value_81;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.status !== undefined) {
            return new TWebAuthenticateStatusResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TWebAuthenticateStatusResponse from input");
        }
    }
}
export interface ITUserIdArgs {
    userId: string;
}
export class TUserId {
    public userId: string;
    constructor(args: ITUserIdArgs) {
        if (args != null && args.userId != null) {
            this.userId = args.userId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[userId] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TUserId");
        if (this.userId != null) {
            output.writeFieldBegin("userId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.userId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TUserId {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_82: string = input.readString();
                        _args.userId = value_82;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.userId !== undefined) {
            return new TUserId(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TUserId from input");
        }
    }
}
export interface ITInviteRequestArgs {
    phoneNumber: string;
    product?: PHeader.TAppProduct;
}
export class TInviteRequest {
    public phoneNumber: string;
    public product?: PHeader.TAppProduct;
    constructor(args: ITInviteRequestArgs) {
        if (args != null && args.phoneNumber != null) {
            this.phoneNumber = args.phoneNumber;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[phoneNumber] is unset!");
        }
        if (args != null && args.product != null) {
            this.product = args.product;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TInviteRequest");
        if (this.phoneNumber != null) {
            output.writeFieldBegin("phoneNumber", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.phoneNumber);
            output.writeFieldEnd();
        }
        if (this.product != null) {
            output.writeFieldBegin("product", thrift.Thrift.Type.I32, 2);
            output.writeI32(this.product);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TInviteRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_83: string = input.readString();
                        _args.phoneNumber = value_83;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_84: PHeader.TAppProduct = input.readI32();
                        _args.product = value_84;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.phoneNumber !== undefined) {
            return new TInviteRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TInviteRequest from input");
        }
    }
}
export interface ITInviteResponseArgs {
}
export class TInviteResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TInviteResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TInviteResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TInviteResponse();
    }
}
export interface ITAuthenticateHeliosOrderDashboardLoginRequestArgs {
    oauthToken: string;
}
export class TAuthenticateHeliosOrderDashboardLoginRequest {
    public oauthToken: string;
    constructor(args: ITAuthenticateHeliosOrderDashboardLoginRequestArgs) {
        if (args != null && args.oauthToken != null) {
            this.oauthToken = args.oauthToken;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[oauthToken] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAuthenticateHeliosOrderDashboardLoginRequest");
        if (this.oauthToken != null) {
            output.writeFieldBegin("oauthToken", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.oauthToken);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAuthenticateHeliosOrderDashboardLoginRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_85: string = input.readString();
                        _args.oauthToken = value_85;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.oauthToken !== undefined) {
            return new TAuthenticateHeliosOrderDashboardLoginRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAuthenticateHeliosOrderDashboardLoginRequest from input");
        }
    }
}
export interface ITAuthenticateHeliosOrderDashboardLoginResponseArgs {
    raterId: string;
    accessToken: string;
    expiresAfter: number | Int64;
}
export class TAuthenticateHeliosOrderDashboardLoginResponse {
    public raterId: string;
    public accessToken: string;
    public expiresAfter: Int64;
    constructor(args: ITAuthenticateHeliosOrderDashboardLoginResponseArgs) {
        if (args != null && args.raterId != null) {
            this.raterId = args.raterId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[raterId] is unset!");
        }
        if (args != null && args.accessToken != null) {
            this.accessToken = args.accessToken;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[accessToken] is unset!");
        }
        if (args != null && args.expiresAfter != null) {
            if (typeof args.expiresAfter === "number") {
                this.expiresAfter = new Int64(args.expiresAfter);
            }
            else {
                this.expiresAfter = args.expiresAfter;
            }
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[expiresAfter] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAuthenticateHeliosOrderDashboardLoginResponse");
        if (this.raterId != null) {
            output.writeFieldBegin("raterId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.raterId);
            output.writeFieldEnd();
        }
        if (this.accessToken != null) {
            output.writeFieldBegin("accessToken", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.accessToken);
            output.writeFieldEnd();
        }
        if (this.expiresAfter != null) {
            output.writeFieldBegin("expiresAfter", thrift.Thrift.Type.I64, 3);
            output.writeI64(this.expiresAfter);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAuthenticateHeliosOrderDashboardLoginResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_86: string = input.readString();
                        _args.raterId = value_86;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_87: string = input.readString();
                        _args.accessToken = value_87;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.I64) {
                        const value_88: Int64 = input.readI64();
                        _args.expiresAfter = value_88;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.raterId !== undefined && _args.accessToken !== undefined && _args.expiresAfter !== undefined) {
            return new TAuthenticateHeliosOrderDashboardLoginResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TAuthenticateHeliosOrderDashboardLoginResponse from input");
        }
    }
}
export interface ITSendEmailSignInCodeRequestArgs {
    emailAddress: string;
}
export class TSendEmailSignInCodeRequest {
    public emailAddress: string;
    constructor(args: ITSendEmailSignInCodeRequestArgs) {
        if (args != null && args.emailAddress != null) {
            this.emailAddress = args.emailAddress;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[emailAddress] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TSendEmailSignInCodeRequest");
        if (this.emailAddress != null) {
            output.writeFieldBegin("emailAddress", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.emailAddress);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TSendEmailSignInCodeRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_89: string = input.readString();
                        _args.emailAddress = value_89;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.emailAddress !== undefined) {
            return new TSendEmailSignInCodeRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TSendEmailSignInCodeRequest from input");
        }
    }
}
export interface ITSendEmailSignInCodeResponseArgs {
    success: boolean;
    errorMessage?: string;
}
export class TSendEmailSignInCodeResponse {
    public success: boolean;
    public errorMessage?: string;
    constructor(args: ITSendEmailSignInCodeResponseArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[success] is unset!");
        }
        if (args != null && args.errorMessage != null) {
            this.errorMessage = args.errorMessage;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TSendEmailSignInCodeResponse");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.BOOL, 1);
            output.writeBool(this.success);
            output.writeFieldEnd();
        }
        if (this.errorMessage != null) {
            output.writeFieldBegin("errorMessage", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.errorMessage);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TSendEmailSignInCodeResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_90: boolean = input.readBool();
                        _args.success = value_90;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_91: string = input.readString();
                        _args.errorMessage = value_91;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.success !== undefined) {
            return new TSendEmailSignInCodeResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TSendEmailSignInCodeResponse from input");
        }
    }
}
export interface ITSignInWithEmailAndCodeRequestArgs {
    emailAddress: string;
    code: string;
}
export class TSignInWithEmailAndCodeRequest {
    public emailAddress: string;
    public code: string;
    constructor(args: ITSignInWithEmailAndCodeRequestArgs) {
        if (args != null && args.emailAddress != null) {
            this.emailAddress = args.emailAddress;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[emailAddress] is unset!");
        }
        if (args != null && args.code != null) {
            this.code = args.code;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[code] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TSignInWithEmailAndCodeRequest");
        if (this.emailAddress != null) {
            output.writeFieldBegin("emailAddress", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.emailAddress);
            output.writeFieldEnd();
        }
        if (this.code != null) {
            output.writeFieldBegin("code", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.code);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TSignInWithEmailAndCodeRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_92: string = input.readString();
                        _args.emailAddress = value_92;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_93: string = input.readString();
                        _args.code = value_93;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.emailAddress !== undefined && _args.code !== undefined) {
            return new TSignInWithEmailAndCodeRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TSignInWithEmailAndCodeRequest from input");
        }
    }
}
export interface ITSignInWithEmailAndCodeResponseArgs {
    success: boolean;
    firebaseCustomToken?: string;
    errorMessage?: string;
    authResponse?: TAuthenticateResponse;
}
export class TSignInWithEmailAndCodeResponse {
    public success: boolean;
    public firebaseCustomToken?: string;
    public errorMessage?: string;
    public authResponse?: TAuthenticateResponse;
    constructor(args: ITSignInWithEmailAndCodeResponseArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[success] is unset!");
        }
        if (args != null && args.firebaseCustomToken != null) {
            this.firebaseCustomToken = args.firebaseCustomToken;
        }
        if (args != null && args.errorMessage != null) {
            this.errorMessage = args.errorMessage;
        }
        if (args != null && args.authResponse != null) {
            this.authResponse = args.authResponse;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TSignInWithEmailAndCodeResponse");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.BOOL, 1);
            output.writeBool(this.success);
            output.writeFieldEnd();
        }
        if (this.firebaseCustomToken != null) {
            output.writeFieldBegin("firebaseCustomToken", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.firebaseCustomToken);
            output.writeFieldEnd();
        }
        if (this.errorMessage != null) {
            output.writeFieldBegin("errorMessage", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.errorMessage);
            output.writeFieldEnd();
        }
        if (this.authResponse != null) {
            output.writeFieldBegin("authResponse", thrift.Thrift.Type.STRUCT, 4);
            this.authResponse.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TSignInWithEmailAndCodeResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_94: boolean = input.readBool();
                        _args.success = value_94;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_95: string = input.readString();
                        _args.firebaseCustomToken = value_95;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_96: string = input.readString();
                        _args.errorMessage = value_96;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_97: TAuthenticateResponse = TAuthenticateResponse.read(input);
                        _args.authResponse = value_97;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.success !== undefined) {
            return new TSignInWithEmailAndCodeResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TSignInWithEmailAndCodeResponse from input");
        }
    }
}
export interface ITSendPhoneSignInCodeRequestArgs {
    phoneNumber: string;
}
export class TSendPhoneSignInCodeRequest {
    public phoneNumber: string;
    constructor(args: ITSendPhoneSignInCodeRequestArgs) {
        if (args != null && args.phoneNumber != null) {
            this.phoneNumber = args.phoneNumber;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[phoneNumber] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TSendPhoneSignInCodeRequest");
        if (this.phoneNumber != null) {
            output.writeFieldBegin("phoneNumber", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.phoneNumber);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TSendPhoneSignInCodeRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_98: string = input.readString();
                        _args.phoneNumber = value_98;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.phoneNumber !== undefined) {
            return new TSendPhoneSignInCodeRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TSendPhoneSignInCodeRequest from input");
        }
    }
}
export interface ITSendPhoneSignInCodeResponseArgs {
    success: boolean;
    errorMessage?: string;
}
export class TSendPhoneSignInCodeResponse {
    public success: boolean;
    public errorMessage?: string;
    constructor(args: ITSendPhoneSignInCodeResponseArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[success] is unset!");
        }
        if (args != null && args.errorMessage != null) {
            this.errorMessage = args.errorMessage;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TSendPhoneSignInCodeResponse");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.BOOL, 1);
            output.writeBool(this.success);
            output.writeFieldEnd();
        }
        if (this.errorMessage != null) {
            output.writeFieldBegin("errorMessage", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.errorMessage);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TSendPhoneSignInCodeResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_99: boolean = input.readBool();
                        _args.success = value_99;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_100: string = input.readString();
                        _args.errorMessage = value_100;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.success !== undefined) {
            return new TSendPhoneSignInCodeResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TSendPhoneSignInCodeResponse from input");
        }
    }
}
export interface ITSignInWithPhoneAndCodeRequestArgs {
    phoneNumber: string;
    code: string;
}
export class TSignInWithPhoneAndCodeRequest {
    public phoneNumber: string;
    public code: string;
    constructor(args: ITSignInWithPhoneAndCodeRequestArgs) {
        if (args != null && args.phoneNumber != null) {
            this.phoneNumber = args.phoneNumber;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[phoneNumber] is unset!");
        }
        if (args != null && args.code != null) {
            this.code = args.code;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[code] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TSignInWithPhoneAndCodeRequest");
        if (this.phoneNumber != null) {
            output.writeFieldBegin("phoneNumber", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.phoneNumber);
            output.writeFieldEnd();
        }
        if (this.code != null) {
            output.writeFieldBegin("code", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.code);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TSignInWithPhoneAndCodeRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_101: string = input.readString();
                        _args.phoneNumber = value_101;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_102: string = input.readString();
                        _args.code = value_102;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.phoneNumber !== undefined && _args.code !== undefined) {
            return new TSignInWithPhoneAndCodeRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TSignInWithPhoneAndCodeRequest from input");
        }
    }
}
export interface ITSignInWithPhoneAndCodeResponseArgs {
    success: boolean;
    firebaseCustomToken?: string;
    errorMessage?: string;
    authResponse?: TAuthenticateResponse;
}
export class TSignInWithPhoneAndCodeResponse {
    public success: boolean;
    public firebaseCustomToken?: string;
    public errorMessage?: string;
    public authResponse?: TAuthenticateResponse;
    constructor(args: ITSignInWithPhoneAndCodeResponseArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[success] is unset!");
        }
        if (args != null && args.firebaseCustomToken != null) {
            this.firebaseCustomToken = args.firebaseCustomToken;
        }
        if (args != null && args.errorMessage != null) {
            this.errorMessage = args.errorMessage;
        }
        if (args != null && args.authResponse != null) {
            this.authResponse = args.authResponse;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TSignInWithPhoneAndCodeResponse");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.BOOL, 1);
            output.writeBool(this.success);
            output.writeFieldEnd();
        }
        if (this.firebaseCustomToken != null) {
            output.writeFieldBegin("firebaseCustomToken", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.firebaseCustomToken);
            output.writeFieldEnd();
        }
        if (this.errorMessage != null) {
            output.writeFieldBegin("errorMessage", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.errorMessage);
            output.writeFieldEnd();
        }
        if (this.authResponse != null) {
            output.writeFieldBegin("authResponse", thrift.Thrift.Type.STRUCT, 4);
            this.authResponse.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TSignInWithPhoneAndCodeResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_103: boolean = input.readBool();
                        _args.success = value_103;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_104: string = input.readString();
                        _args.firebaseCustomToken = value_104;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_105: string = input.readString();
                        _args.errorMessage = value_105;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_106: TAuthenticateResponse = TAuthenticateResponse.read(input);
                        _args.authResponse = value_106;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.success !== undefined) {
            return new TSignInWithPhoneAndCodeResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TSignInWithPhoneAndCodeResponse from input");
        }
    }
}
export interface ITAppVerifyWithPiiCodeResponseArgs {
    addPiiSuccess?: TAppPiiCodeVerificationSuccess;
    mergePrompt?: TAppPromptUserMerge;
}
export class TAppVerifyWithPiiCodeResponse {
    public addPiiSuccess?: TAppPiiCodeVerificationSuccess;
    public mergePrompt?: TAppPromptUserMerge;
    constructor(args?: ITAppVerifyWithPiiCodeResponseArgs) {
        let _fieldsSet: number = 0;
        if (args != null) {
            if (args.addPiiSuccess != null) {
                _fieldsSet++;
                this.addPiiSuccess = args.addPiiSuccess;
            }
            if (args.mergePrompt != null) {
                _fieldsSet++;
                this.mergePrompt = args.mergePrompt;
            }
            if (_fieldsSet > 1) {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with more than one set value!");
            }
            else if (_fieldsSet < 1) {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with no set value!");
            }
        }
    }
    public static fromAddPiiSuccess(addPiiSuccess: TAppPiiCodeVerificationSuccess): TAppVerifyWithPiiCodeResponse {
        return new TAppVerifyWithPiiCodeResponse({ addPiiSuccess });
    }
    public static fromMergePrompt(mergePrompt: TAppPromptUserMerge): TAppVerifyWithPiiCodeResponse {
        return new TAppVerifyWithPiiCodeResponse({ mergePrompt });
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppVerifyWithPiiCodeResponse");
        if (this.addPiiSuccess != null) {
            output.writeFieldBegin("addPiiSuccess", thrift.Thrift.Type.STRUCT, 1);
            this.addPiiSuccess.write(output);
            output.writeFieldEnd();
        }
        if (this.mergePrompt != null) {
            output.writeFieldBegin("mergePrompt", thrift.Thrift.Type.STRUCT, 2);
            this.mergePrompt.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppVerifyWithPiiCodeResponse {
        let _fieldsSet: number = 0;
        let _returnValue: TAppVerifyWithPiiCodeResponse | null = null;
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        _fieldsSet++;
                        const value_107: TAppPiiCodeVerificationSuccess = TAppPiiCodeVerificationSuccess.read(input);
                        _returnValue = TAppVerifyWithPiiCodeResponse.fromAddPiiSuccess(value_107);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        _fieldsSet++;
                        const value_108: TAppPromptUserMerge = TAppPromptUserMerge.read(input);
                        _returnValue = TAppVerifyWithPiiCodeResponse.fromMergePrompt(value_108);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_fieldsSet > 1) {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with more than one set value!");
        }
        else if (_fieldsSet < 1) {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with no set value!");
        }
        if (_returnValue !== null) {
            return _returnValue;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read data for TUnion");
        }
    }
}
export interface ITAppPiiArgs {
    phone?: string;
    email?: string;
}
export class TAppPii {
    public phone?: string;
    public email?: string;
    constructor(args?: ITAppPiiArgs) {
        let _fieldsSet: number = 0;
        if (args != null) {
            if (args.phone != null) {
                _fieldsSet++;
                this.phone = args.phone;
            }
            if (args.email != null) {
                _fieldsSet++;
                this.email = args.email;
            }
            if (_fieldsSet > 1) {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with more than one set value!");
            }
            else if (_fieldsSet < 1) {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with no set value!");
            }
        }
    }
    public static fromPhone(phone: string): TAppPii {
        return new TAppPii({ phone });
    }
    public static fromEmail(email: string): TAppPii {
        return new TAppPii({ email });
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppPii");
        if (this.phone != null) {
            output.writeFieldBegin("phone", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.phone);
            output.writeFieldEnd();
        }
        if (this.email != null) {
            output.writeFieldBegin("email", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.email);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppPii {
        let _fieldsSet: number = 0;
        let _returnValue: TAppPii | null = null;
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        _fieldsSet++;
                        const value_109: string = input.readString();
                        _returnValue = TAppPii.fromPhone(value_109);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        _fieldsSet++;
                        const value_110: string = input.readString();
                        _returnValue = TAppPii.fromEmail(value_110);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_fieldsSet > 1) {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with more than one set value!");
        }
        else if (_fieldsSet < 1) {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with no set value!");
        }
        if (_returnValue !== null) {
            return _returnValue;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read data for TUnion");
        }
    }
}
