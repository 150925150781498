/* tslint:disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v2.0.14
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
import * as PContact from "./../../common/models";
import * as SelfContact from "./../../contactssync/messages";
import * as AuthenticateCommon from "./../../authentication/messages";
import * as OAuth from "./../../common/models";
import * as PHeader from "./../../common/models";
import * as PCommon from "./../../common/models";
export interface ITGetImportedAccountsRequestArgs {
}
export class TGetImportedAccountsRequest {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TGetImportedAccountsRequest");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TGetImportedAccountsRequest {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TGetImportedAccountsRequest();
    }
}
export interface ITGetImportedAccountsResponseArgs {
    importedAccounts: Map<string, string>;
}
export class TGetImportedAccountsResponse {
    public importedAccounts: Map<string, string>;
    constructor(args: ITGetImportedAccountsResponseArgs) {
        if (args != null && args.importedAccounts != null) {
            this.importedAccounts = args.importedAccounts;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[importedAccounts] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TGetImportedAccountsResponse");
        if (this.importedAccounts != null) {
            output.writeFieldBegin("importedAccounts", thrift.Thrift.Type.MAP, 1);
            output.writeMapBegin(thrift.Thrift.Type.STRING, thrift.Thrift.Type.STRING, this.importedAccounts.size);
            this.importedAccounts.forEach((value_1: string, key_1: string): void => {
                output.writeString(key_1);
                output.writeString(value_1);
            });
            output.writeMapEnd();
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TGetImportedAccountsResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.MAP) {
                        const value_2: Map<string, string> = new Map<string, string>();
                        const metadata_1: thrift.TMap = input.readMapBegin();
                        const size_1: number = metadata_1.size;
                        for (let i_1: number = 0; i_1 < size_1; i_1++) {
                            const key_2: string = input.readString();
                            const value_3: string = input.readString();
                            value_2.set(key_2, value_3);
                        }
                        input.readMapEnd();
                        _args.importedAccounts = value_2;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.importedAccounts !== undefined) {
            return new TGetImportedAccountsResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TGetImportedAccountsResponse from input");
        }
    }
}
export interface ITUpdateCanonicalPhoneRequestArgs {
    newPhoneNumber?: string;
}
export class TUpdateCanonicalPhoneRequest {
    public newPhoneNumber?: string;
    constructor(args?: ITUpdateCanonicalPhoneRequestArgs) {
        if (args != null && args.newPhoneNumber != null) {
            this.newPhoneNumber = args.newPhoneNumber;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TUpdateCanonicalPhoneRequest");
        if (this.newPhoneNumber != null) {
            output.writeFieldBegin("newPhoneNumber", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.newPhoneNumber);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TUpdateCanonicalPhoneRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_4: string = input.readString();
                        _args.newPhoneNumber = value_4;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TUpdateCanonicalPhoneRequest(_args);
    }
}
export interface ITUpdateCanonicalPhoneResponseArgs {
    contactInfoToConfirm?: PContact.TAppContact;
    currentUserSelfContact?: PContact.TAppContact;
}
export class TUpdateCanonicalPhoneResponse {
    public contactInfoToConfirm?: PContact.TAppContact;
    public currentUserSelfContact?: PContact.TAppContact;
    constructor(args?: ITUpdateCanonicalPhoneResponseArgs) {
        if (args != null && args.contactInfoToConfirm != null) {
            this.contactInfoToConfirm = args.contactInfoToConfirm;
        }
        if (args != null && args.currentUserSelfContact != null) {
            this.currentUserSelfContact = args.currentUserSelfContact;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TUpdateCanonicalPhoneResponse");
        if (this.contactInfoToConfirm != null) {
            output.writeFieldBegin("contactInfoToConfirm", thrift.Thrift.Type.STRUCT, 1);
            this.contactInfoToConfirm.write(output);
            output.writeFieldEnd();
        }
        if (this.currentUserSelfContact != null) {
            output.writeFieldBegin("currentUserSelfContact", thrift.Thrift.Type.STRUCT, 2);
            this.currentUserSelfContact.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TUpdateCanonicalPhoneResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_5: PContact.TAppContact = PContact.TAppContact.read(input);
                        _args.contactInfoToConfirm = value_5;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_6: PContact.TAppContact = PContact.TAppContact.read(input);
                        _args.currentUserSelfContact = value_6;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TUpdateCanonicalPhoneResponse(_args);
    }
}
export interface ITConfirmUserMergeRequestArgs {
    pii?: Array<AuthenticateCommon.TAppPii>;
}
export class TConfirmUserMergeRequest {
    public pii?: Array<AuthenticateCommon.TAppPii>;
    constructor(args?: ITConfirmUserMergeRequestArgs) {
        if (args != null && args.pii != null) {
            this.pii = args.pii;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TConfirmUserMergeRequest");
        if (this.pii != null) {
            output.writeFieldBegin("pii", thrift.Thrift.Type.LIST, 1);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.pii.length);
            this.pii.forEach((value_7: AuthenticateCommon.TAppPii): void => {
                value_7.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TConfirmUserMergeRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_8: Array<AuthenticateCommon.TAppPii> = new Array<AuthenticateCommon.TAppPii>();
                        const metadata_2: thrift.TList = input.readListBegin();
                        const size_2: number = metadata_2.size;
                        for (let i_2: number = 0; i_2 < size_2; i_2++) {
                            const value_9: AuthenticateCommon.TAppPii = AuthenticateCommon.TAppPii.read(input);
                            value_8.push(value_9);
                        }
                        input.readListEnd();
                        _args.pii = value_8;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TConfirmUserMergeRequest(_args);
    }
}
export interface ITConfirmUserMergeResponseArgs {
    mergedPii: Array<AuthenticateCommon.TAppPii>;
    shouldLogUserOut?: boolean;
}
export class TConfirmUserMergeResponse {
    public mergedPii: Array<AuthenticateCommon.TAppPii>;
    public shouldLogUserOut?: boolean;
    constructor(args: ITConfirmUserMergeResponseArgs) {
        if (args != null && args.mergedPii != null) {
            this.mergedPii = args.mergedPii;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[mergedPii] is unset!");
        }
        if (args != null && args.shouldLogUserOut != null) {
            this.shouldLogUserOut = args.shouldLogUserOut;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TConfirmUserMergeResponse");
        if (this.mergedPii != null) {
            output.writeFieldBegin("mergedPii", thrift.Thrift.Type.LIST, 1);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.mergedPii.length);
            this.mergedPii.forEach((value_10: AuthenticateCommon.TAppPii): void => {
                value_10.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.shouldLogUserOut != null) {
            output.writeFieldBegin("shouldLogUserOut", thrift.Thrift.Type.BOOL, 2);
            output.writeBool(this.shouldLogUserOut);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TConfirmUserMergeResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_11: Array<AuthenticateCommon.TAppPii> = new Array<AuthenticateCommon.TAppPii>();
                        const metadata_3: thrift.TList = input.readListBegin();
                        const size_3: number = metadata_3.size;
                        for (let i_3: number = 0; i_3 < size_3; i_3++) {
                            const value_12: AuthenticateCommon.TAppPii = AuthenticateCommon.TAppPii.read(input);
                            value_11.push(value_12);
                        }
                        input.readListEnd();
                        _args.mergedPii = value_11;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_13: boolean = input.readBool();
                        _args.shouldLogUserOut = value_13;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.mergedPii !== undefined) {
            return new TConfirmUserMergeResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TConfirmUserMergeResponse from input");
        }
    }
}
export interface ITContactProcessingStatusRequestArgs {
}
export class TContactProcessingStatusRequest {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TContactProcessingStatusRequest");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TContactProcessingStatusRequest {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TContactProcessingStatusRequest();
    }
}
export interface ITContactProcessingStatusResponseArgs {
    processingContacts?: boolean;
    iPhoneEnhancementsComplete?: boolean;
    emailBasedEnhancementsComplete?: Map<string, boolean>;
}
export class TContactProcessingStatusResponse {
    public processingContacts?: boolean;
    public iPhoneEnhancementsComplete?: boolean;
    public emailBasedEnhancementsComplete?: Map<string, boolean>;
    constructor(args?: ITContactProcessingStatusResponseArgs) {
        if (args != null && args.processingContacts != null) {
            this.processingContacts = args.processingContacts;
        }
        if (args != null && args.iPhoneEnhancementsComplete != null) {
            this.iPhoneEnhancementsComplete = args.iPhoneEnhancementsComplete;
        }
        if (args != null && args.emailBasedEnhancementsComplete != null) {
            this.emailBasedEnhancementsComplete = args.emailBasedEnhancementsComplete;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TContactProcessingStatusResponse");
        if (this.processingContacts != null) {
            output.writeFieldBegin("processingContacts", thrift.Thrift.Type.BOOL, 1);
            output.writeBool(this.processingContacts);
            output.writeFieldEnd();
        }
        if (this.iPhoneEnhancementsComplete != null) {
            output.writeFieldBegin("iPhoneEnhancementsComplete", thrift.Thrift.Type.BOOL, 2);
            output.writeBool(this.iPhoneEnhancementsComplete);
            output.writeFieldEnd();
        }
        if (this.emailBasedEnhancementsComplete != null) {
            output.writeFieldBegin("emailBasedEnhancementsComplete", thrift.Thrift.Type.MAP, 3);
            output.writeMapBegin(thrift.Thrift.Type.STRING, thrift.Thrift.Type.BOOL, this.emailBasedEnhancementsComplete.size);
            this.emailBasedEnhancementsComplete.forEach((value_14: boolean, key_3: string): void => {
                output.writeString(key_3);
                output.writeBool(value_14);
            });
            output.writeMapEnd();
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TContactProcessingStatusResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_15: boolean = input.readBool();
                        _args.processingContacts = value_15;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_16: boolean = input.readBool();
                        _args.iPhoneEnhancementsComplete = value_16;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.MAP) {
                        const value_17: Map<string, boolean> = new Map<string, boolean>();
                        const metadata_4: thrift.TMap = input.readMapBegin();
                        const size_4: number = metadata_4.size;
                        for (let i_4: number = 0; i_4 < size_4; i_4++) {
                            const key_4: string = input.readString();
                            const value_18: boolean = input.readBool();
                            value_17.set(key_4, value_18);
                        }
                        input.readMapEnd();
                        _args.emailBasedEnhancementsComplete = value_17;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TContactProcessingStatusResponse(_args);
    }
}
export enum TDistressType {
    UNDEFINED = 0,
    SYNC = 1
}
export interface ITDistressRequestArgs {
    distressType: TDistressType;
    reason: string;
}
export class TDistressRequest {
    public distressType: TDistressType;
    public reason: string;
    constructor(args: ITDistressRequestArgs) {
        if (args != null && args.distressType != null) {
            this.distressType = args.distressType;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[distressType] is unset!");
        }
        if (args != null && args.reason != null) {
            this.reason = args.reason;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[reason] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TDistressRequest");
        if (this.distressType != null) {
            output.writeFieldBegin("distressType", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.distressType);
            output.writeFieldEnd();
        }
        if (this.reason != null) {
            output.writeFieldBegin("reason", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.reason);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TDistressRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_19: TDistressType = input.readI32();
                        _args.distressType = value_19;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_20: string = input.readString();
                        _args.reason = value_20;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.distressType !== undefined && _args.reason !== undefined) {
            return new TDistressRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TDistressRequest from input");
        }
    }
}
export interface ITDistressResponseArgs {
}
export class TDistressResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TDistressResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TDistressResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TDistressResponse();
    }
}
export interface ITEncryptionStorageRequestArgs {
    encryptionKey: string;
}
export class TEncryptionStorageRequest {
    public encryptionKey: string;
    constructor(args: ITEncryptionStorageRequestArgs) {
        if (args != null && args.encryptionKey != null) {
            this.encryptionKey = args.encryptionKey;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[encryptionKey] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TEncryptionStorageRequest");
        if (this.encryptionKey != null) {
            output.writeFieldBegin("encryptionKey", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.encryptionKey);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TEncryptionStorageRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_21: string = input.readString();
                        _args.encryptionKey = value_21;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.encryptionKey !== undefined) {
            return new TEncryptionStorageRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TEncryptionStorageRequest from input");
        }
    }
}
export interface ITEncryptionStorageResponseArgs {
}
export class TEncryptionStorageResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TEncryptionStorageResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TEncryptionStorageResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TEncryptionStorageResponse();
    }
}
export interface ITGetAuthorizationUrlRequestArgs {
    oauthProvider: OAuth.TAppOAuthProvider;
}
export class TGetAuthorizationUrlRequest {
    public oauthProvider: OAuth.TAppOAuthProvider;
    constructor(args: ITGetAuthorizationUrlRequestArgs) {
        if (args != null && args.oauthProvider != null) {
            this.oauthProvider = args.oauthProvider;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[oauthProvider] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TGetAuthorizationUrlRequest");
        if (this.oauthProvider != null) {
            output.writeFieldBegin("oauthProvider", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.oauthProvider);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TGetAuthorizationUrlRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_22: OAuth.TAppOAuthProvider = input.readI32();
                        _args.oauthProvider = value_22;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.oauthProvider !== undefined) {
            return new TGetAuthorizationUrlRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TGetAuthorizationUrlRequest from input");
        }
    }
}
export interface ITGetAuthorizationUrlResponseArgs {
    authorizationUrl: string;
}
export class TGetAuthorizationUrlResponse {
    public authorizationUrl: string;
    constructor(args: ITGetAuthorizationUrlResponseArgs) {
        if (args != null && args.authorizationUrl != null) {
            this.authorizationUrl = args.authorizationUrl;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[authorizationUrl] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TGetAuthorizationUrlResponse");
        if (this.authorizationUrl != null) {
            output.writeFieldBegin("authorizationUrl", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.authorizationUrl);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TGetAuthorizationUrlResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_23: string = input.readString();
                        _args.authorizationUrl = value_23;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.authorizationUrl !== undefined) {
            return new TGetAuthorizationUrlResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TGetAuthorizationUrlResponse from input");
        }
    }
}
export interface ITGoogleAuthTokensRequestArgs {
    authToken?: string;
    refreshToken?: string;
}
export class TGoogleAuthTokensRequest {
    public authToken?: string;
    public refreshToken?: string;
    constructor(args?: ITGoogleAuthTokensRequestArgs) {
        if (args != null && args.authToken != null) {
            this.authToken = args.authToken;
        }
        if (args != null && args.refreshToken != null) {
            this.refreshToken = args.refreshToken;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TGoogleAuthTokensRequest");
        if (this.authToken != null) {
            output.writeFieldBegin("authToken", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.authToken);
            output.writeFieldEnd();
        }
        if (this.refreshToken != null) {
            output.writeFieldBegin("refreshToken", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.refreshToken);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TGoogleAuthTokensRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_24: string = input.readString();
                        _args.authToken = value_24;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_25: string = input.readString();
                        _args.refreshToken = value_25;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TGoogleAuthTokensRequest(_args);
    }
}
export interface ITGoogleAuthTokensResponseArgs {
}
export class TGoogleAuthTokensResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TGoogleAuthTokensResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TGoogleAuthTokensResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TGoogleAuthTokensResponse();
    }
}
export interface ITAppInitialHeliosDataArgs {
    birthdayContacts?: Array<PContact.TAppContact>;
}
export class TAppInitialHeliosData {
    public birthdayContacts?: Array<PContact.TAppContact>;
    constructor(args?: ITAppInitialHeliosDataArgs) {
        if (args != null && args.birthdayContacts != null) {
            this.birthdayContacts = args.birthdayContacts;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppInitialHeliosData");
        if (this.birthdayContacts != null) {
            output.writeFieldBegin("birthdayContacts", thrift.Thrift.Type.LIST, 1);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.birthdayContacts.length);
            this.birthdayContacts.forEach((value_26: PContact.TAppContact): void => {
                value_26.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppInitialHeliosData {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_27: Array<PContact.TAppContact> = new Array<PContact.TAppContact>();
                        const metadata_5: thrift.TList = input.readListBegin();
                        const size_5: number = metadata_5.size;
                        for (let i_5: number = 0; i_5 < size_5; i_5++) {
                            const value_28: PContact.TAppContact = PContact.TAppContact.read(input);
                            value_27.push(value_28);
                        }
                        input.readListEnd();
                        _args.birthdayContacts = value_27;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TAppInitialHeliosData(_args);
    }
}
export interface ITGetInitialDataRequestArgs {
    product: PHeader.TAppProduct;
}
export class TGetInitialDataRequest {
    public product: PHeader.TAppProduct;
    constructor(args: ITGetInitialDataRequestArgs) {
        if (args != null && args.product != null) {
            this.product = args.product;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[product] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TGetInitialDataRequest");
        if (this.product != null) {
            output.writeFieldBegin("product", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.product);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TGetInitialDataRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_29: PHeader.TAppProduct = input.readI32();
                        _args.product = value_29;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.product !== undefined) {
            return new TGetInitialDataRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TGetInitialDataRequest from input");
        }
    }
}
export interface ITGetInitialDataResponseArgs {
    data?: TAppInitialData;
}
export class TGetInitialDataResponse {
    public data?: TAppInitialData;
    constructor(args?: ITGetInitialDataResponseArgs) {
        if (args != null && args.data != null) {
            this.data = args.data;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TGetInitialDataResponse");
        if (this.data != null) {
            output.writeFieldBegin("data", thrift.Thrift.Type.STRUCT, 1);
            this.data.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TGetInitialDataResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_30: TAppInitialData = TAppInitialData.read(input);
                        _args.data = value_30;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TGetInitialDataResponse(_args);
    }
}
export interface ITAppInitialDataArgs {
    heliosData?: TAppInitialHeliosData;
}
export class TAppInitialData {
    public heliosData?: TAppInitialHeliosData;
    constructor(args?: ITAppInitialDataArgs) {
        let _fieldsSet: number = 0;
        if (args != null) {
            if (args.heliosData != null) {
                _fieldsSet++;
                this.heliosData = args.heliosData;
            }
            if (_fieldsSet > 1) {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with more than one set value!");
            }
            else if (_fieldsSet < 1) {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with no set value!");
            }
        }
    }
    public static fromHeliosData(heliosData: TAppInitialHeliosData): TAppInitialData {
        return new TAppInitialData({ heliosData });
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppInitialData");
        if (this.heliosData != null) {
            output.writeFieldBegin("heliosData", thrift.Thrift.Type.STRUCT, 1);
            this.heliosData.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppInitialData {
        let _fieldsSet: number = 0;
        let _returnValue: TAppInitialData | null = null;
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        _fieldsSet++;
                        const value_31: TAppInitialHeliosData = TAppInitialHeliosData.read(input);
                        _returnValue = TAppInitialData.fromHeliosData(value_31);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_fieldsSet > 1) {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with more than one set value!");
        }
        else if (_fieldsSet < 1) {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.INVALID_DATA, "Cannot read a TUnion with no set value!");
        }
        if (_returnValue !== null) {
            return _returnValue;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read data for TUnion");
        }
    }
}
export interface ITMicrosoftAuthTokensRequestArgs {
    authToken?: string;
    refreshToken?: string;
}
export class TMicrosoftAuthTokensRequest {
    public authToken?: string;
    public refreshToken?: string;
    constructor(args?: ITMicrosoftAuthTokensRequestArgs) {
        if (args != null && args.authToken != null) {
            this.authToken = args.authToken;
        }
        if (args != null && args.refreshToken != null) {
            this.refreshToken = args.refreshToken;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TMicrosoftAuthTokensRequest");
        if (this.authToken != null) {
            output.writeFieldBegin("authToken", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.authToken);
            output.writeFieldEnd();
        }
        if (this.refreshToken != null) {
            output.writeFieldBegin("refreshToken", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.refreshToken);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TMicrosoftAuthTokensRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_32: string = input.readString();
                        _args.authToken = value_32;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_33: string = input.readString();
                        _args.refreshToken = value_33;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TMicrosoftAuthTokensRequest(_args);
    }
}
export interface ITMicrosoftAuthTokensResponseArgs {
}
export class TMicrosoftAuthTokensResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TMicrosoftAuthTokensResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TMicrosoftAuthTokensResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TMicrosoftAuthTokensResponse();
    }
}
export interface ITOptOutRequestArgs {
}
export class TOptOutRequest {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TOptOutRequest");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TOptOutRequest {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TOptOutRequest();
    }
}
export interface ITOptOutResponseArgs {
    shouldDeleteUser?: boolean;
}
export class TOptOutResponse {
    public shouldDeleteUser?: boolean;
    constructor(args?: ITOptOutResponseArgs) {
        if (args != null && args.shouldDeleteUser != null) {
            this.shouldDeleteUser = args.shouldDeleteUser;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TOptOutResponse");
        if (this.shouldDeleteUser != null) {
            output.writeFieldBegin("shouldDeleteUser", thrift.Thrift.Type.BOOL, 1);
            output.writeBool(this.shouldDeleteUser);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TOptOutResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_34: boolean = input.readBool();
                        _args.shouldDeleteUser = value_34;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TOptOutResponse(_args);
    }
}
export interface ITContactLooksGoodRequestArgs {
    contact: PContact.TAppContact;
}
export class TContactLooksGoodRequest {
    public contact: PContact.TAppContact;
    constructor(args: ITContactLooksGoodRequestArgs) {
        if (args != null && args.contact != null) {
            this.contact = args.contact;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[contact] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TContactLooksGoodRequest");
        if (this.contact != null) {
            output.writeFieldBegin("contact", thrift.Thrift.Type.STRUCT, 1);
            this.contact.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TContactLooksGoodRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_35: PContact.TAppContact = PContact.TAppContact.read(input);
                        _args.contact = value_35;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.contact !== undefined) {
            return new TContactLooksGoodRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TContactLooksGoodRequest from input");
        }
    }
}
export interface ITContactLooksGoodResponseArgs {
}
export class TContactLooksGoodResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TContactLooksGoodResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TContactLooksGoodResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TContactLooksGoodResponse();
    }
}
export interface ITUpdateTopContactStatusRequestArgs {
    topContactId: string;
    accept: boolean;
}
export class TUpdateTopContactStatusRequest {
    public topContactId: string;
    public accept: boolean;
    constructor(args: ITUpdateTopContactStatusRequestArgs) {
        if (args != null && args.topContactId != null) {
            this.topContactId = args.topContactId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[topContactId] is unset!");
        }
        if (args != null && args.accept != null) {
            this.accept = args.accept;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[accept] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TUpdateTopContactStatusRequest");
        if (this.topContactId != null) {
            output.writeFieldBegin("topContactId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.topContactId);
            output.writeFieldEnd();
        }
        if (this.accept != null) {
            output.writeFieldBegin("accept", thrift.Thrift.Type.BOOL, 2);
            output.writeBool(this.accept);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TUpdateTopContactStatusRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_36: string = input.readString();
                        _args.topContactId = value_36;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_37: boolean = input.readBool();
                        _args.accept = value_37;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.topContactId !== undefined && _args.accept !== undefined) {
            return new TUpdateTopContactStatusRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TUpdateTopContactStatusRequest from input");
        }
    }
}
export interface ITUpdateTopContactStatusResponseArgs {
}
export class TUpdateTopContactStatusResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TUpdateTopContactStatusResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TUpdateTopContactStatusResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TUpdateTopContactStatusResponse();
    }
}
export interface ITUpdateUserMetadataRequestArgs {
    notificationToken: string;
}
export class TUpdateUserMetadataRequest {
    public notificationToken: string;
    constructor(args: ITUpdateUserMetadataRequestArgs) {
        if (args != null && args.notificationToken != null) {
            this.notificationToken = args.notificationToken;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[notificationToken] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TUpdateUserMetadataRequest");
        if (this.notificationToken != null) {
            output.writeFieldBegin("notificationToken", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.notificationToken);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TUpdateUserMetadataRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_38: string = input.readString();
                        _args.notificationToken = value_38;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.notificationToken !== undefined) {
            return new TUpdateUserMetadataRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TUpdateUserMetadataRequest from input");
        }
    }
}
export interface ITUpdateUserMetadataResponseArgs {
    success: boolean;
}
export class TUpdateUserMetadataResponse {
    public success: boolean;
    constructor(args: ITUpdateUserMetadataResponseArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[success] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TUpdateUserMetadataResponse");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.BOOL, 1);
            output.writeBool(this.success);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TUpdateUserMetadataResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_39: boolean = input.readBool();
                        _args.success = value_39;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.success !== undefined) {
            return new TUpdateUserMetadataResponse(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TUpdateUserMetadataResponse from input");
        }
    }
}
export interface ITAppHeliosWeeklyEmailScheduleArgs {
    isEnabled?: boolean;
    dayTime?: PCommon.TAppDayTime;
}
export class TAppHeliosWeeklyEmailSchedule {
    public isEnabled?: boolean;
    public dayTime?: PCommon.TAppDayTime;
    constructor(args?: ITAppHeliosWeeklyEmailScheduleArgs) {
        if (args != null && args.isEnabled != null) {
            this.isEnabled = args.isEnabled;
        }
        if (args != null && args.dayTime != null) {
            this.dayTime = args.dayTime;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppHeliosWeeklyEmailSchedule");
        if (this.isEnabled != null) {
            output.writeFieldBegin("isEnabled", thrift.Thrift.Type.BOOL, 1);
            output.writeBool(this.isEnabled);
            output.writeFieldEnd();
        }
        if (this.dayTime != null) {
            output.writeFieldBegin("dayTime", thrift.Thrift.Type.STRUCT, 2);
            this.dayTime.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppHeliosWeeklyEmailSchedule {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_40: boolean = input.readBool();
                        _args.isEnabled = value_40;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_41: PCommon.TAppDayTime = PCommon.TAppDayTime.read(input);
                        _args.dayTime = value_41;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TAppHeliosWeeklyEmailSchedule(_args);
    }
}
export interface ITAppHeliosPushNotificationSettingsArgs {
    isEnabled?: boolean;
}
export class TAppHeliosPushNotificationSettings {
    public isEnabled?: boolean;
    constructor(args?: ITAppHeliosPushNotificationSettingsArgs) {
        if (args != null && args.isEnabled != null) {
            this.isEnabled = args.isEnabled;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TAppHeliosPushNotificationSettings");
        if (this.isEnabled != null) {
            output.writeFieldBegin("isEnabled", thrift.Thrift.Type.BOOL, 1);
            output.writeBool(this.isEnabled);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TAppHeliosPushNotificationSettings {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_42: boolean = input.readBool();
                        _args.isEnabled = value_42;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TAppHeliosPushNotificationSettings(_args);
    }
}
export interface ITUpdateUserSettingsRequestArgs {
    heliosWeeklyEmailSchedule?: TAppHeliosWeeklyEmailSchedule;
    timeZoneId?: string;
    heliosPushNotificationSettings?: TAppHeliosPushNotificationSettings;
}
export class TUpdateUserSettingsRequest {
    public heliosWeeklyEmailSchedule?: TAppHeliosWeeklyEmailSchedule;
    public timeZoneId?: string;
    public heliosPushNotificationSettings?: TAppHeliosPushNotificationSettings;
    constructor(args?: ITUpdateUserSettingsRequestArgs) {
        if (args != null && args.heliosWeeklyEmailSchedule != null) {
            this.heliosWeeklyEmailSchedule = args.heliosWeeklyEmailSchedule;
        }
        if (args != null && args.timeZoneId != null) {
            this.timeZoneId = args.timeZoneId;
        }
        if (args != null && args.heliosPushNotificationSettings != null) {
            this.heliosPushNotificationSettings = args.heliosPushNotificationSettings;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TUpdateUserSettingsRequest");
        if (this.heliosWeeklyEmailSchedule != null) {
            output.writeFieldBegin("heliosWeeklyEmailSchedule", thrift.Thrift.Type.STRUCT, 1);
            this.heliosWeeklyEmailSchedule.write(output);
            output.writeFieldEnd();
        }
        if (this.timeZoneId != null) {
            output.writeFieldBegin("timeZoneId", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.timeZoneId);
            output.writeFieldEnd();
        }
        if (this.heliosPushNotificationSettings != null) {
            output.writeFieldBegin("heliosPushNotificationSettings", thrift.Thrift.Type.STRUCT, 3);
            this.heliosPushNotificationSettings.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TUpdateUserSettingsRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_43: TAppHeliosWeeklyEmailSchedule = TAppHeliosWeeklyEmailSchedule.read(input);
                        _args.heliosWeeklyEmailSchedule = value_43;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_44: string = input.readString();
                        _args.timeZoneId = value_44;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_45: TAppHeliosPushNotificationSettings = TAppHeliosPushNotificationSettings.read(input);
                        _args.heliosPushNotificationSettings = value_45;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TUpdateUserSettingsRequest(_args);
    }
}
export interface ITUpdateUserSettingsResponseArgs {
}
export class TUpdateUserSettingsResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TUpdateUserSettingsResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TUpdateUserSettingsResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TUpdateUserSettingsResponse();
    }
}
export interface ITFetchEmailScheduleRequestArgs {
}
export class TFetchEmailScheduleRequest {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TFetchEmailScheduleRequest");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TFetchEmailScheduleRequest {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TFetchEmailScheduleRequest();
    }
}
export interface ITFetchEmailScheduleResponseArgs {
    dayTime?: PCommon.TAppDayTime;
}
export class TFetchEmailScheduleResponse {
    public dayTime?: PCommon.TAppDayTime;
    constructor(args?: ITFetchEmailScheduleResponseArgs) {
        if (args != null && args.dayTime != null) {
            this.dayTime = args.dayTime;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TFetchEmailScheduleResponse");
        if (this.dayTime != null) {
            output.writeFieldBegin("dayTime", thrift.Thrift.Type.STRUCT, 1);
            this.dayTime.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TFetchEmailScheduleResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_46: PCommon.TAppDayTime = PCommon.TAppDayTime.read(input);
                        _args.dayTime = value_46;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TFetchEmailScheduleResponse(_args);
    }
}
export interface ITUpdateSilentNotificationTokenRequestArgs {
    deviceId: string;
    silentNotificationToken: string;
}
export class TUpdateSilentNotificationTokenRequest {
    public deviceId: string;
    public silentNotificationToken: string;
    constructor(args: ITUpdateSilentNotificationTokenRequestArgs) {
        if (args != null && args.deviceId != null) {
            this.deviceId = args.deviceId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[deviceId] is unset!");
        }
        if (args != null && args.silentNotificationToken != null) {
            this.silentNotificationToken = args.silentNotificationToken;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[silentNotificationToken] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TUpdateSilentNotificationTokenRequest");
        if (this.deviceId != null) {
            output.writeFieldBegin("deviceId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.deviceId);
            output.writeFieldEnd();
        }
        if (this.silentNotificationToken != null) {
            output.writeFieldBegin("silentNotificationToken", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.silentNotificationToken);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TUpdateSilentNotificationTokenRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_47: string = input.readString();
                        _args.deviceId = value_47;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_48: string = input.readString();
                        _args.silentNotificationToken = value_48;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.deviceId !== undefined && _args.silentNotificationToken !== undefined) {
            return new TUpdateSilentNotificationTokenRequest(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read TUpdateSilentNotificationTokenRequest from input");
        }
    }
}
export interface ITUpdateSilentNotificationTokenResponseArgs {
}
export class TUpdateSilentNotificationTokenResponse {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TUpdateSilentNotificationTokenResponse");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TUpdateSilentNotificationTokenResponse {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TUpdateSilentNotificationTokenResponse();
    }
}
export interface ITSaveUserHealthStatsRequestArgs {
    userId?: string;
    healthStats?: Map<string, string>;
}
export class TSaveUserHealthStatsRequest {
    public userId?: string;
    public healthStats?: Map<string, string>;
    constructor(args?: ITSaveUserHealthStatsRequestArgs) {
        if (args != null && args.userId != null) {
            this.userId = args.userId;
        }
        if (args != null && args.healthStats != null) {
            this.healthStats = args.healthStats;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TSaveUserHealthStatsRequest");
        if (this.userId != null) {
            output.writeFieldBegin("userId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.userId);
            output.writeFieldEnd();
        }
        if (this.healthStats != null) {
            output.writeFieldBegin("healthStats", thrift.Thrift.Type.MAP, 2);
            output.writeMapBegin(thrift.Thrift.Type.STRING, thrift.Thrift.Type.STRING, this.healthStats.size);
            this.healthStats.forEach((value_49: string, key_5: string): void => {
                output.writeString(key_5);
                output.writeString(value_49);
            });
            output.writeMapEnd();
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TSaveUserHealthStatsRequest {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_50: string = input.readString();
                        _args.userId = value_50;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.MAP) {
                        const value_51: Map<string, string> = new Map<string, string>();
                        const metadata_6: thrift.TMap = input.readMapBegin();
                        const size_6: number = metadata_6.size;
                        for (let i_6: number = 0; i_6 < size_6; i_6++) {
                            const key_6: string = input.readString();
                            const value_52: string = input.readString();
                            value_51.set(key_6, value_52);
                        }
                        input.readMapEnd();
                        _args.healthStats = value_51;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TSaveUserHealthStatsRequest(_args);
    }
}
export interface ITSaveUserHealthStatsResponseArgs {
    success?: boolean;
}
export class TSaveUserHealthStatsResponse {
    public success?: boolean;
    constructor(args?: ITSaveUserHealthStatsResponseArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("TSaveUserHealthStatsResponse");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.BOOL, 1);
            output.writeBool(this.success);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): TSaveUserHealthStatsResponse {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_53: boolean = input.readBool();
                        _args.success = value_53;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new TSaveUserHealthStatsResponse(_args);
    }
}
