/* tslint:disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v2.0.14
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
import * as Authenticate from "./messages";
import * as GenericExceptions from "./../common/exceptions";
import * as PHeader from "./../common/models";
export namespace TAuthenticationService {
    export interface IAuthenticateArgsArgs {
        request: Authenticate.TAuthenticateRequest;
        header?: PHeader.TUnauthHeader;
    }
    export class AuthenticateArgs {
        public request: Authenticate.TAuthenticateRequest;
        public header?: PHeader.TUnauthHeader;
        constructor(args: IAuthenticateArgsArgs) {
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
            if (args != null && args.header != null) {
                this.header = args.header;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("AuthenticateArgs");
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 1);
                this.request.write(output);
                output.writeFieldEnd();
            }
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 2);
                this.header.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): AuthenticateArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_1: Authenticate.TAuthenticateRequest = Authenticate.TAuthenticateRequest.read(input);
                            _args.request = value_1;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_2: PHeader.TUnauthHeader = PHeader.TUnauthHeader.read(input);
                            _args.header = value_2;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.request !== undefined) {
                return new AuthenticateArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read AuthenticateArgs from input");
            }
        }
    }
    export interface ICreateAndAuthenticateUserArgsArgs {
        request: Authenticate.TCreateAndAuthenticateUserRequest;
        header?: PHeader.TUnauthHeader;
    }
    export class CreateAndAuthenticateUserArgs {
        public request: Authenticate.TCreateAndAuthenticateUserRequest;
        public header?: PHeader.TUnauthHeader;
        constructor(args: ICreateAndAuthenticateUserArgsArgs) {
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
            if (args != null && args.header != null) {
                this.header = args.header;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("CreateAndAuthenticateUserArgs");
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 1);
                this.request.write(output);
                output.writeFieldEnd();
            }
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 2);
                this.header.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): CreateAndAuthenticateUserArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_3: Authenticate.TCreateAndAuthenticateUserRequest = Authenticate.TCreateAndAuthenticateUserRequest.read(input);
                            _args.request = value_3;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_4: PHeader.TUnauthHeader = PHeader.TUnauthHeader.read(input);
                            _args.header = value_4;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.request !== undefined) {
                return new CreateAndAuthenticateUserArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read CreateAndAuthenticateUserArgs from input");
            }
        }
    }
    export interface IRefetchAccessTokenArgsArgs {
        request: Authenticate.TRefetchAccessTokenRequest;
        header?: PHeader.TUnauthHeader;
    }
    export class RefetchAccessTokenArgs {
        public request: Authenticate.TRefetchAccessTokenRequest;
        public header?: PHeader.TUnauthHeader;
        constructor(args: IRefetchAccessTokenArgsArgs) {
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
            if (args != null && args.header != null) {
                this.header = args.header;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("RefetchAccessTokenArgs");
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 1);
                this.request.write(output);
                output.writeFieldEnd();
            }
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 2);
                this.header.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): RefetchAccessTokenArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_5: Authenticate.TRefetchAccessTokenRequest = Authenticate.TRefetchAccessTokenRequest.read(input);
                            _args.request = value_5;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_6: PHeader.TUnauthHeader = PHeader.TUnauthHeader.read(input);
                            _args.header = value_6;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.request !== undefined) {
                return new RefetchAccessTokenArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read RefetchAccessTokenArgs from input");
            }
        }
    }
    export interface IAuthenticateEmailArgsArgs {
        request: Authenticate.TAuthenticateEmailRequest;
        header?: PHeader.TUnauthHeader;
    }
    export class AuthenticateEmailArgs {
        public request: Authenticate.TAuthenticateEmailRequest;
        public header?: PHeader.TUnauthHeader;
        constructor(args: IAuthenticateEmailArgsArgs) {
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
            if (args != null && args.header != null) {
                this.header = args.header;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("AuthenticateEmailArgs");
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 1);
                this.request.write(output);
                output.writeFieldEnd();
            }
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 2);
                this.header.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): AuthenticateEmailArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_7: Authenticate.TAuthenticateEmailRequest = Authenticate.TAuthenticateEmailRequest.read(input);
                            _args.request = value_7;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_8: PHeader.TUnauthHeader = PHeader.TUnauthHeader.read(input);
                            _args.header = value_8;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.request !== undefined) {
                return new AuthenticateEmailArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read AuthenticateEmailArgs from input");
            }
        }
    }
    export interface ISendPiiVerificationCodeArgsArgs {
        header: PHeader.TAuthHeader;
        request: Authenticate.TSendPiiVerificationCodeRequest;
    }
    export class SendPiiVerificationCodeArgs {
        public header: PHeader.TAuthHeader;
        public request: Authenticate.TSendPiiVerificationCodeRequest;
        constructor(args: ISendPiiVerificationCodeArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SendPiiVerificationCodeArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SendPiiVerificationCodeArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_9: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_9;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_10: Authenticate.TSendPiiVerificationCodeRequest = Authenticate.TSendPiiVerificationCodeRequest.read(input);
                            _args.request = value_10;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new SendPiiVerificationCodeArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read SendPiiVerificationCodeArgs from input");
            }
        }
    }
    export interface IVerifyWithPiiAndCodeArgsArgs {
        header: PHeader.TAuthHeader;
        request: Authenticate.TVerifyWithPiiCodeRequest;
    }
    export class VerifyWithPiiAndCodeArgs {
        public header: PHeader.TAuthHeader;
        public request: Authenticate.TVerifyWithPiiCodeRequest;
        constructor(args: IVerifyWithPiiAndCodeArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("VerifyWithPiiAndCodeArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): VerifyWithPiiAndCodeArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_11: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_11;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_12: Authenticate.TVerifyWithPiiCodeRequest = Authenticate.TVerifyWithPiiCodeRequest.read(input);
                            _args.request = value_12;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new VerifyWithPiiAndCodeArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read VerifyWithPiiAndCodeArgs from input");
            }
        }
    }
    export interface IAuthenticateRaterArgsArgs {
        request: Authenticate.TAuthenticateRaterRequest;
        header?: PHeader.TUnauthHeader;
    }
    export class AuthenticateRaterArgs {
        public request: Authenticate.TAuthenticateRaterRequest;
        public header?: PHeader.TUnauthHeader;
        constructor(args: IAuthenticateRaterArgsArgs) {
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
            if (args != null && args.header != null) {
                this.header = args.header;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("AuthenticateRaterArgs");
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 1);
                this.request.write(output);
                output.writeFieldEnd();
            }
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 2);
                this.header.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): AuthenticateRaterArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_13: Authenticate.TAuthenticateRaterRequest = Authenticate.TAuthenticateRaterRequest.read(input);
                            _args.request = value_13;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_14: PHeader.TUnauthHeader = PHeader.TUnauthHeader.read(input);
                            _args.header = value_14;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.request !== undefined) {
                return new AuthenticateRaterArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read AuthenticateRaterArgs from input");
            }
        }
    }
    export interface ISendEmailVerificationArgsArgs {
        request: Authenticate.TSendEmailVerificationRequest;
        header?: PHeader.TUnauthHeader;
    }
    export class SendEmailVerificationArgs {
        public request: Authenticate.TSendEmailVerificationRequest;
        public header?: PHeader.TUnauthHeader;
        constructor(args: ISendEmailVerificationArgsArgs) {
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
            if (args != null && args.header != null) {
                this.header = args.header;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SendEmailVerificationArgs");
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 1);
                this.request.write(output);
                output.writeFieldEnd();
            }
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 2);
                this.header.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SendEmailVerificationArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_15: Authenticate.TSendEmailVerificationRequest = Authenticate.TSendEmailVerificationRequest.read(input);
                            _args.request = value_15;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_16: PHeader.TUnauthHeader = PHeader.TUnauthHeader.read(input);
                            _args.header = value_16;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.request !== undefined) {
                return new SendEmailVerificationArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read SendEmailVerificationArgs from input");
            }
        }
    }
    export interface ISendWebAuthRequestArgsArgs {
        request: Authenticate.TWebAuthenticateRequest;
        header?: PHeader.TUnauthHeader;
    }
    export class SendWebAuthRequestArgs {
        public request: Authenticate.TWebAuthenticateRequest;
        public header?: PHeader.TUnauthHeader;
        constructor(args: ISendWebAuthRequestArgsArgs) {
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
            if (args != null && args.header != null) {
                this.header = args.header;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SendWebAuthRequestArgs");
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 1);
                this.request.write(output);
                output.writeFieldEnd();
            }
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 2);
                this.header.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SendWebAuthRequestArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_17: Authenticate.TWebAuthenticateRequest = Authenticate.TWebAuthenticateRequest.read(input);
                            _args.request = value_17;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_18: PHeader.TUnauthHeader = PHeader.TUnauthHeader.read(input);
                            _args.header = value_18;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.request !== undefined) {
                return new SendWebAuthRequestArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read SendWebAuthRequestArgs from input");
            }
        }
    }
    export interface IUpdateWebAuthRequestArgsArgs {
        header: PHeader.TAuthHeader;
        request: Authenticate.TWebAuthenticateUpdateRequest;
    }
    export class UpdateWebAuthRequestArgs {
        public header: PHeader.TAuthHeader;
        public request: Authenticate.TWebAuthenticateUpdateRequest;
        constructor(args: IUpdateWebAuthRequestArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateWebAuthRequestArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateWebAuthRequestArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_19: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_19;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_20: Authenticate.TWebAuthenticateUpdateRequest = Authenticate.TWebAuthenticateUpdateRequest.read(input);
                            _args.request = value_20;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new UpdateWebAuthRequestArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read UpdateWebAuthRequestArgs from input");
            }
        }
    }
    export interface IGetActiveWebAuthRequestArgsArgs {
        header: PHeader.TAuthHeader;
        userId: Authenticate.TUserId;
    }
    export class GetActiveWebAuthRequestArgs {
        public header: PHeader.TAuthHeader;
        public userId: Authenticate.TUserId;
        constructor(args: IGetActiveWebAuthRequestArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.userId != null) {
                this.userId = args.userId;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[userId] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetActiveWebAuthRequestArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.userId != null) {
                output.writeFieldBegin("userId", thrift.Thrift.Type.STRUCT, 2);
                this.userId.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetActiveWebAuthRequestArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_21: PHeader.TAuthHeader = PHeader.TAuthHeader.read(input);
                            _args.header = value_21;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_22: Authenticate.TUserId = Authenticate.TUserId.read(input);
                            _args.userId = value_22;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.userId !== undefined) {
                return new GetActiveWebAuthRequestArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GetActiveWebAuthRequestArgs from input");
            }
        }
    }
    export interface IRequestInviteArgsArgs {
        request: Authenticate.TInviteRequest;
        header?: PHeader.TUnauthHeader;
    }
    export class RequestInviteArgs {
        public request: Authenticate.TInviteRequest;
        public header?: PHeader.TUnauthHeader;
        constructor(args: IRequestInviteArgsArgs) {
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
            if (args != null && args.header != null) {
                this.header = args.header;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("RequestInviteArgs");
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 1);
                this.request.write(output);
                output.writeFieldEnd();
            }
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 2);
                this.header.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): RequestInviteArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_23: Authenticate.TInviteRequest = Authenticate.TInviteRequest.read(input);
                            _args.request = value_23;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_24: PHeader.TUnauthHeader = PHeader.TUnauthHeader.read(input);
                            _args.header = value_24;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.request !== undefined) {
                return new RequestInviteArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read RequestInviteArgs from input");
            }
        }
    }
    export interface IAuthenticateHeliosDashboardUserArgsArgs {
        request: Authenticate.TAuthenticateHeliosOrderDashboardLoginRequest;
        header?: PHeader.TUnauthHeader;
    }
    export class AuthenticateHeliosDashboardUserArgs {
        public request: Authenticate.TAuthenticateHeliosOrderDashboardLoginRequest;
        public header?: PHeader.TUnauthHeader;
        constructor(args: IAuthenticateHeliosDashboardUserArgsArgs) {
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
            if (args != null && args.header != null) {
                this.header = args.header;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("AuthenticateHeliosDashboardUserArgs");
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 1);
                this.request.write(output);
                output.writeFieldEnd();
            }
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 2);
                this.header.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): AuthenticateHeliosDashboardUserArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_25: Authenticate.TAuthenticateHeliosOrderDashboardLoginRequest = Authenticate.TAuthenticateHeliosOrderDashboardLoginRequest.read(input);
                            _args.request = value_25;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_26: PHeader.TUnauthHeader = PHeader.TUnauthHeader.read(input);
                            _args.header = value_26;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.request !== undefined) {
                return new AuthenticateHeliosDashboardUserArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read AuthenticateHeliosDashboardUserArgs from input");
            }
        }
    }
    export interface ISendEmailSignInCodeArgsArgs {
        header: PHeader.TUnauthHeader;
        request: Authenticate.TSendEmailSignInCodeRequest;
    }
    export class SendEmailSignInCodeArgs {
        public header: PHeader.TUnauthHeader;
        public request: Authenticate.TSendEmailSignInCodeRequest;
        constructor(args: ISendEmailSignInCodeArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SendEmailSignInCodeArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SendEmailSignInCodeArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_27: PHeader.TUnauthHeader = PHeader.TUnauthHeader.read(input);
                            _args.header = value_27;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_28: Authenticate.TSendEmailSignInCodeRequest = Authenticate.TSendEmailSignInCodeRequest.read(input);
                            _args.request = value_28;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new SendEmailSignInCodeArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read SendEmailSignInCodeArgs from input");
            }
        }
    }
    export interface ISignInWithEmailAndCodeArgsArgs {
        header: PHeader.TUnauthHeader;
        request: Authenticate.TSignInWithEmailAndCodeRequest;
    }
    export class SignInWithEmailAndCodeArgs {
        public header: PHeader.TUnauthHeader;
        public request: Authenticate.TSignInWithEmailAndCodeRequest;
        constructor(args: ISignInWithEmailAndCodeArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SignInWithEmailAndCodeArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SignInWithEmailAndCodeArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_29: PHeader.TUnauthHeader = PHeader.TUnauthHeader.read(input);
                            _args.header = value_29;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_30: Authenticate.TSignInWithEmailAndCodeRequest = Authenticate.TSignInWithEmailAndCodeRequest.read(input);
                            _args.request = value_30;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new SignInWithEmailAndCodeArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read SignInWithEmailAndCodeArgs from input");
            }
        }
    }
    export interface ISendPhoneSignInCodeArgsArgs {
        header: PHeader.TUnauthHeader;
        request: Authenticate.TSendPhoneSignInCodeRequest;
    }
    export class SendPhoneSignInCodeArgs {
        public header: PHeader.TUnauthHeader;
        public request: Authenticate.TSendPhoneSignInCodeRequest;
        constructor(args: ISendPhoneSignInCodeArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SendPhoneSignInCodeArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SendPhoneSignInCodeArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_31: PHeader.TUnauthHeader = PHeader.TUnauthHeader.read(input);
                            _args.header = value_31;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_32: Authenticate.TSendPhoneSignInCodeRequest = Authenticate.TSendPhoneSignInCodeRequest.read(input);
                            _args.request = value_32;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new SendPhoneSignInCodeArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read SendPhoneSignInCodeArgs from input");
            }
        }
    }
    export interface ISignInWithPhoneAndCodeArgsArgs {
        header: PHeader.TUnauthHeader;
        request: Authenticate.TSignInWithPhoneAndCodeRequest;
    }
    export class SignInWithPhoneAndCodeArgs {
        public header: PHeader.TUnauthHeader;
        public request: Authenticate.TSignInWithPhoneAndCodeRequest;
        constructor(args: ISignInWithPhoneAndCodeArgsArgs) {
            if (args != null && args.header != null) {
                this.header = args.header;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[header] is unset!");
            }
            if (args != null && args.request != null) {
                this.request = args.request;
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[request] is unset!");
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SignInWithPhoneAndCodeArgs");
            if (this.header != null) {
                output.writeFieldBegin("header", thrift.Thrift.Type.STRUCT, 1);
                this.header.write(output);
                output.writeFieldEnd();
            }
            if (this.request != null) {
                output.writeFieldBegin("request", thrift.Thrift.Type.STRUCT, 2);
                this.request.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SignInWithPhoneAndCodeArgs {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_33: PHeader.TUnauthHeader = PHeader.TUnauthHeader.read(input);
                            _args.header = value_33;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_34: Authenticate.TSignInWithPhoneAndCodeRequest = Authenticate.TSignInWithPhoneAndCodeRequest.read(input);
                            _args.request = value_34;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            if (_args.header !== undefined && _args.request !== undefined) {
                return new SignInWithPhoneAndCodeArgs(_args);
            }
            else {
                throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read SignInWithPhoneAndCodeArgs from input");
            }
        }
    }
    export interface IAuthenticateResultArgs {
        success?: Authenticate.TAuthenticateResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
        inviteError?: GenericExceptions.TInviteException;
    }
    export class AuthenticateResult {
        public success?: Authenticate.TAuthenticateResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        public inviteError?: GenericExceptions.TInviteException;
        constructor(args?: IAuthenticateResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
            if (args != null && args.inviteError != null) {
                this.inviteError = args.inviteError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("AuthenticateResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            if (this.inviteError != null) {
                output.writeFieldBegin("inviteError", thrift.Thrift.Type.STRUCT, 3);
                this.inviteError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): AuthenticateResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_35: Authenticate.TAuthenticateResponse = Authenticate.TAuthenticateResponse.read(input);
                            _args.success = value_35;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_36: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_36;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_37: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_37;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 3:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_38: GenericExceptions.TInviteException = GenericExceptions.TInviteException.read(input);
                            _args.inviteError = value_38;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new AuthenticateResult(_args);
        }
    }
    export interface ICreateAndAuthenticateUserResultArgs {
        success?: Authenticate.TAuthenticateResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
        inviteError?: GenericExceptions.TInviteException;
    }
    export class CreateAndAuthenticateUserResult {
        public success?: Authenticate.TAuthenticateResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        public inviteError?: GenericExceptions.TInviteException;
        constructor(args?: ICreateAndAuthenticateUserResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
            if (args != null && args.inviteError != null) {
                this.inviteError = args.inviteError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("CreateAndAuthenticateUserResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            if (this.inviteError != null) {
                output.writeFieldBegin("inviteError", thrift.Thrift.Type.STRUCT, 3);
                this.inviteError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): CreateAndAuthenticateUserResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_39: Authenticate.TAuthenticateResponse = Authenticate.TAuthenticateResponse.read(input);
                            _args.success = value_39;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_40: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_40;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_41: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_41;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 3:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_42: GenericExceptions.TInviteException = GenericExceptions.TInviteException.read(input);
                            _args.inviteError = value_42;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new CreateAndAuthenticateUserResult(_args);
        }
    }
    export interface IRefetchAccessTokenResultArgs {
        success?: Authenticate.TRefetchAccessTokenResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class RefetchAccessTokenResult {
        public success?: Authenticate.TRefetchAccessTokenResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IRefetchAccessTokenResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("RefetchAccessTokenResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): RefetchAccessTokenResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_43: Authenticate.TRefetchAccessTokenResponse = Authenticate.TRefetchAccessTokenResponse.read(input);
                            _args.success = value_43;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_44: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_44;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_45: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_45;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new RefetchAccessTokenResult(_args);
        }
    }
    export interface IAuthenticateEmailResultArgs {
        success?: Authenticate.TAuthenticateEmailResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class AuthenticateEmailResult {
        public success?: Authenticate.TAuthenticateEmailResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IAuthenticateEmailResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("AuthenticateEmailResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): AuthenticateEmailResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_46: Authenticate.TAuthenticateEmailResponse = Authenticate.TAuthenticateEmailResponse.read(input);
                            _args.success = value_46;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_47: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_47;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_48: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_48;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new AuthenticateEmailResult(_args);
        }
    }
    export interface ISendPiiVerificationCodeResultArgs {
        success?: Authenticate.TSendPiiVerificationCodeResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class SendPiiVerificationCodeResult {
        public success?: Authenticate.TSendPiiVerificationCodeResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: ISendPiiVerificationCodeResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SendPiiVerificationCodeResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SendPiiVerificationCodeResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_49: Authenticate.TSendPiiVerificationCodeResponse = Authenticate.TSendPiiVerificationCodeResponse.read(input);
                            _args.success = value_49;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_50: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_50;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_51: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_51;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new SendPiiVerificationCodeResult(_args);
        }
    }
    export interface IVerifyWithPiiAndCodeResultArgs {
        success?: Authenticate.TVerifyWithPiiCodeResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class VerifyWithPiiAndCodeResult {
        public success?: Authenticate.TVerifyWithPiiCodeResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IVerifyWithPiiAndCodeResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("VerifyWithPiiAndCodeResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): VerifyWithPiiAndCodeResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_52: Authenticate.TVerifyWithPiiCodeResponse = Authenticate.TVerifyWithPiiCodeResponse.read(input);
                            _args.success = value_52;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_53: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_53;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_54: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_54;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new VerifyWithPiiAndCodeResult(_args);
        }
    }
    export interface IAuthenticateRaterResultArgs {
        success?: Authenticate.TAuthenticateRaterResponse;
        authFailedError?: GenericExceptions.TAuthFailed;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class AuthenticateRaterResult {
        public success?: Authenticate.TAuthenticateRaterResponse;
        public authFailedError?: GenericExceptions.TAuthFailed;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IAuthenticateRaterResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("AuthenticateRaterResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 1);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 2);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): AuthenticateRaterResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_55: Authenticate.TAuthenticateRaterResponse = Authenticate.TAuthenticateRaterResponse.read(input);
                            _args.success = value_55;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_56: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_56;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_57: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_57;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new AuthenticateRaterResult(_args);
        }
    }
    export interface ISendEmailVerificationResultArgs {
        success?: Authenticate.TSendEmailVerificationResponse;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class SendEmailVerificationResult {
        public success?: Authenticate.TSendEmailVerificationResponse;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: ISendEmailVerificationResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SendEmailVerificationResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 1);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SendEmailVerificationResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_58: Authenticate.TSendEmailVerificationResponse = Authenticate.TSendEmailVerificationResponse.read(input);
                            _args.success = value_58;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_59: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_59;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new SendEmailVerificationResult(_args);
        }
    }
    export interface ISendWebAuthRequestResultArgs {
        success?: Authenticate.TWebAuthenticateStatusResponse;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class SendWebAuthRequestResult {
        public success?: Authenticate.TWebAuthenticateStatusResponse;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: ISendWebAuthRequestResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SendWebAuthRequestResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 1);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SendWebAuthRequestResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_60: Authenticate.TWebAuthenticateStatusResponse = Authenticate.TWebAuthenticateStatusResponse.read(input);
                            _args.success = value_60;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_61: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_61;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new SendWebAuthRequestResult(_args);
        }
    }
    export interface IUpdateWebAuthRequestResultArgs {
        success?: Authenticate.TWebAuthenticateUpdateResponse;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class UpdateWebAuthRequestResult {
        public success?: Authenticate.TWebAuthenticateUpdateResponse;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IUpdateWebAuthRequestResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("UpdateWebAuthRequestResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 1);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): UpdateWebAuthRequestResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_62: Authenticate.TWebAuthenticateUpdateResponse = Authenticate.TWebAuthenticateUpdateResponse.read(input);
                            _args.success = value_62;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_63: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_63;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new UpdateWebAuthRequestResult(_args);
        }
    }
    export interface IGetActiveWebAuthRequestResultArgs {
        success?: Authenticate.TActiveWebAuthResponse;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class GetActiveWebAuthRequestResult {
        public success?: Authenticate.TActiveWebAuthResponse;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IGetActiveWebAuthRequestResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("GetActiveWebAuthRequestResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 1);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): GetActiveWebAuthRequestResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_64: Authenticate.TActiveWebAuthResponse = Authenticate.TActiveWebAuthResponse.read(input);
                            _args.success = value_64;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_65: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_65;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new GetActiveWebAuthRequestResult(_args);
        }
    }
    export interface IRequestInviteResultArgs {
        success?: Authenticate.TInviteResponse;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class RequestInviteResult {
        public success?: Authenticate.TInviteResponse;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: IRequestInviteResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("RequestInviteResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 1);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): RequestInviteResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_66: Authenticate.TInviteResponse = Authenticate.TInviteResponse.read(input);
                            _args.success = value_66;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_67: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_67;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new RequestInviteResult(_args);
        }
    }
    export interface IAuthenticateHeliosDashboardUserResultArgs {
        success?: Authenticate.TAuthenticateHeliosOrderDashboardLoginResponse;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
        authFailedError?: GenericExceptions.TAuthFailed;
    }
    export class AuthenticateHeliosDashboardUserResult {
        public success?: Authenticate.TAuthenticateHeliosOrderDashboardLoginResponse;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        public authFailedError?: GenericExceptions.TAuthFailed;
        constructor(args?: IAuthenticateHeliosDashboardUserResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("AuthenticateHeliosDashboardUserResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 1);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 2);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): AuthenticateHeliosDashboardUserResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_68: Authenticate.TAuthenticateHeliosOrderDashboardLoginResponse = Authenticate.TAuthenticateHeliosOrderDashboardLoginResponse.read(input);
                            _args.success = value_68;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_69: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_69;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_70: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_70;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new AuthenticateHeliosDashboardUserResult(_args);
        }
    }
    export interface ISendEmailSignInCodeResultArgs {
        success?: Authenticate.TSendEmailSignInCodeResponse;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class SendEmailSignInCodeResult {
        public success?: Authenticate.TSendEmailSignInCodeResponse;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: ISendEmailSignInCodeResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SendEmailSignInCodeResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 1);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SendEmailSignInCodeResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_71: Authenticate.TSendEmailSignInCodeResponse = Authenticate.TSendEmailSignInCodeResponse.read(input);
                            _args.success = value_71;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_72: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_72;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new SendEmailSignInCodeResult(_args);
        }
    }
    export interface ISignInWithEmailAndCodeResultArgs {
        success?: Authenticate.TSignInWithEmailAndCodeResponse;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
        authFailedError?: GenericExceptions.TAuthFailed;
    }
    export class SignInWithEmailAndCodeResult {
        public success?: Authenticate.TSignInWithEmailAndCodeResponse;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        public authFailedError?: GenericExceptions.TAuthFailed;
        constructor(args?: ISignInWithEmailAndCodeResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SignInWithEmailAndCodeResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 1);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 2);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SignInWithEmailAndCodeResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_73: Authenticate.TSignInWithEmailAndCodeResponse = Authenticate.TSignInWithEmailAndCodeResponse.read(input);
                            _args.success = value_73;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_74: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_74;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_75: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_75;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new SignInWithEmailAndCodeResult(_args);
        }
    }
    export interface ISendPhoneSignInCodeResultArgs {
        success?: Authenticate.TSendPhoneSignInCodeResponse;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
    }
    export class SendPhoneSignInCodeResult {
        public success?: Authenticate.TSendPhoneSignInCodeResponse;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        constructor(args?: ISendPhoneSignInCodeResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SendPhoneSignInCodeResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 1);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SendPhoneSignInCodeResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_76: Authenticate.TSendPhoneSignInCodeResponse = Authenticate.TSendPhoneSignInCodeResponse.read(input);
                            _args.success = value_76;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_77: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_77;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new SendPhoneSignInCodeResult(_args);
        }
    }
    export interface ISignInWithPhoneAndCodeResultArgs {
        success?: Authenticate.TSignInWithPhoneAndCodeResponse;
        invalidRequestError?: GenericExceptions.TInvalidRequest;
        authFailedError?: GenericExceptions.TAuthFailed;
    }
    export class SignInWithPhoneAndCodeResult {
        public success?: Authenticate.TSignInWithPhoneAndCodeResponse;
        public invalidRequestError?: GenericExceptions.TInvalidRequest;
        public authFailedError?: GenericExceptions.TAuthFailed;
        constructor(args?: ISignInWithPhoneAndCodeResultArgs) {
            if (args != null && args.success != null) {
                this.success = args.success;
            }
            if (args != null && args.invalidRequestError != null) {
                this.invalidRequestError = args.invalidRequestError;
            }
            if (args != null && args.authFailedError != null) {
                this.authFailedError = args.authFailedError;
            }
        }
        public write(output: thrift.TProtocol): void {
            output.writeStructBegin("SignInWithPhoneAndCodeResult");
            if (this.success != null) {
                output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
                this.success.write(output);
                output.writeFieldEnd();
            }
            if (this.invalidRequestError != null) {
                output.writeFieldBegin("invalidRequestError", thrift.Thrift.Type.STRUCT, 1);
                this.invalidRequestError.write(output);
                output.writeFieldEnd();
            }
            if (this.authFailedError != null) {
                output.writeFieldBegin("authFailedError", thrift.Thrift.Type.STRUCT, 2);
                this.authFailedError.write(output);
                output.writeFieldEnd();
            }
            output.writeFieldStop();
            output.writeStructEnd();
            return;
        }
        public static read(input: thrift.TProtocol): SignInWithPhoneAndCodeResult {
            input.readStructBegin();
            let _args: any = {};
            while (true) {
                const ret: thrift.TField = input.readFieldBegin();
                const fieldType: thrift.Thrift.Type = ret.ftype;
                const fieldId: number = ret.fid;
                if (fieldType === thrift.Thrift.Type.STOP) {
                    break;
                }
                switch (fieldId) {
                    case 0:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_78: Authenticate.TSignInWithPhoneAndCodeResponse = Authenticate.TSignInWithPhoneAndCodeResponse.read(input);
                            _args.success = value_78;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 1:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_79: GenericExceptions.TInvalidRequest = GenericExceptions.TInvalidRequest.read(input);
                            _args.invalidRequestError = value_79;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    case 2:
                        if (fieldType === thrift.Thrift.Type.STRUCT) {
                            const value_80: GenericExceptions.TAuthFailed = GenericExceptions.TAuthFailed.read(input);
                            _args.authFailedError = value_80;
                        }
                        else {
                            input.skip(fieldType);
                        }
                        break;
                    default: {
                        input.skip(fieldType);
                    }
                }
                input.readFieldEnd();
            }
            input.readStructEnd();
            return new SignInWithPhoneAndCodeResult(_args);
        }
    }
    export class Client {
        public _seqid: number;
        public _reqs: {
            [name: number]: (err: Error | object | undefined, val?: any) => void;
        };
        public output: thrift.TTransport;
        public protocol: new (trans: thrift.TTransport) => thrift.TProtocol;
        constructor(output: thrift.TTransport, protocol: new (trans: thrift.TTransport) => thrift.TProtocol) {
            this._seqid = 0;
            this._reqs = {};
            this.output = output;
            this.protocol = protocol;
        }
        public incrementSeqId(): number {
            return this._seqid += 1;
        }
        public authenticate(request: Authenticate.TAuthenticateRequest, header?: PHeader.TUnauthHeader): Promise<Authenticate.TAuthenticateResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<Authenticate.TAuthenticateResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_authenticate(request, header, requestId);
            });
        }
        public createAndAuthenticateUser(request: Authenticate.TCreateAndAuthenticateUserRequest, header?: PHeader.TUnauthHeader): Promise<Authenticate.TAuthenticateResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<Authenticate.TAuthenticateResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_createAndAuthenticateUser(request, header, requestId);
            });
        }
        public refetchAccessToken(request: Authenticate.TRefetchAccessTokenRequest, header?: PHeader.TUnauthHeader): Promise<Authenticate.TRefetchAccessTokenResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<Authenticate.TRefetchAccessTokenResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_refetchAccessToken(request, header, requestId);
            });
        }
        public authenticateEmail(request: Authenticate.TAuthenticateEmailRequest, header?: PHeader.TUnauthHeader): Promise<Authenticate.TAuthenticateEmailResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<Authenticate.TAuthenticateEmailResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_authenticateEmail(request, header, requestId);
            });
        }
        public sendPiiVerificationCode(header: PHeader.TAuthHeader, request: Authenticate.TSendPiiVerificationCodeRequest): Promise<Authenticate.TSendPiiVerificationCodeResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<Authenticate.TSendPiiVerificationCodeResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_sendPiiVerificationCode(header, request, requestId);
            });
        }
        public verifyWithPiiAndCode(header: PHeader.TAuthHeader, request: Authenticate.TVerifyWithPiiCodeRequest): Promise<Authenticate.TVerifyWithPiiCodeResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<Authenticate.TVerifyWithPiiCodeResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_verifyWithPiiAndCode(header, request, requestId);
            });
        }
        public authenticateRater(request: Authenticate.TAuthenticateRaterRequest, header?: PHeader.TUnauthHeader): Promise<Authenticate.TAuthenticateRaterResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<Authenticate.TAuthenticateRaterResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_authenticateRater(request, header, requestId);
            });
        }
        public sendEmailVerification(request: Authenticate.TSendEmailVerificationRequest, header?: PHeader.TUnauthHeader): Promise<Authenticate.TSendEmailVerificationResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<Authenticate.TSendEmailVerificationResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_sendEmailVerification(request, header, requestId);
            });
        }
        public sendWebAuthRequest(request: Authenticate.TWebAuthenticateRequest, header?: PHeader.TUnauthHeader): Promise<Authenticate.TWebAuthenticateStatusResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<Authenticate.TWebAuthenticateStatusResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_sendWebAuthRequest(request, header, requestId);
            });
        }
        public updateWebAuthRequest(header: PHeader.TAuthHeader, request: Authenticate.TWebAuthenticateUpdateRequest): Promise<Authenticate.TWebAuthenticateUpdateResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<Authenticate.TWebAuthenticateUpdateResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_updateWebAuthRequest(header, request, requestId);
            });
        }
        public getActiveWebAuthRequest(header: PHeader.TAuthHeader, userId: Authenticate.TUserId): Promise<Authenticate.TActiveWebAuthResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<Authenticate.TActiveWebAuthResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_getActiveWebAuthRequest(header, userId, requestId);
            });
        }
        public requestInvite(request: Authenticate.TInviteRequest, header?: PHeader.TUnauthHeader): Promise<Authenticate.TInviteResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<Authenticate.TInviteResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_requestInvite(request, header, requestId);
            });
        }
        public authenticateHeliosDashboardUser(request: Authenticate.TAuthenticateHeliosOrderDashboardLoginRequest, header?: PHeader.TUnauthHeader): Promise<Authenticate.TAuthenticateHeliosOrderDashboardLoginResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<Authenticate.TAuthenticateHeliosOrderDashboardLoginResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_authenticateHeliosDashboardUser(request, header, requestId);
            });
        }
        public sendEmailSignInCode(header: PHeader.TUnauthHeader, request: Authenticate.TSendEmailSignInCodeRequest): Promise<Authenticate.TSendEmailSignInCodeResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<Authenticate.TSendEmailSignInCodeResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_sendEmailSignInCode(header, request, requestId);
            });
        }
        public signInWithEmailAndCode(header: PHeader.TUnauthHeader, request: Authenticate.TSignInWithEmailAndCodeRequest): Promise<Authenticate.TSignInWithEmailAndCodeResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<Authenticate.TSignInWithEmailAndCodeResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_signInWithEmailAndCode(header, request, requestId);
            });
        }
        public sendPhoneSignInCode(header: PHeader.TUnauthHeader, request: Authenticate.TSendPhoneSignInCodeRequest): Promise<Authenticate.TSendPhoneSignInCodeResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<Authenticate.TSendPhoneSignInCodeResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_sendPhoneSignInCode(header, request, requestId);
            });
        }
        public signInWithPhoneAndCode(header: PHeader.TUnauthHeader, request: Authenticate.TSignInWithPhoneAndCodeRequest): Promise<Authenticate.TSignInWithPhoneAndCodeResponse> {
            const requestId: number = this.incrementSeqId();
            return new Promise<Authenticate.TSignInWithPhoneAndCodeResponse>((resolve, reject): void => {
                this._reqs[requestId] = (error, result) => {
                    delete this._reqs[requestId];
                    if (error != null) {
                        reject(error);
                    }
                    else {
                        resolve(result);
                    }
                };
                this.send_signInWithPhoneAndCode(header, request, requestId);
            });
        }
        public send_authenticate(request: Authenticate.TAuthenticateRequest, header: PHeader.TUnauthHeader | undefined, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("authenticate", thrift.Thrift.MessageType.CALL, requestId);
            const args: AuthenticateArgs = new AuthenticateArgs({ request, header });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_createAndAuthenticateUser(request: Authenticate.TCreateAndAuthenticateUserRequest, header: PHeader.TUnauthHeader | undefined, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("createAndAuthenticateUser", thrift.Thrift.MessageType.CALL, requestId);
            const args: CreateAndAuthenticateUserArgs = new CreateAndAuthenticateUserArgs({ request, header });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_refetchAccessToken(request: Authenticate.TRefetchAccessTokenRequest, header: PHeader.TUnauthHeader | undefined, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("refetchAccessToken", thrift.Thrift.MessageType.CALL, requestId);
            const args: RefetchAccessTokenArgs = new RefetchAccessTokenArgs({ request, header });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_authenticateEmail(request: Authenticate.TAuthenticateEmailRequest, header: PHeader.TUnauthHeader | undefined, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("authenticateEmail", thrift.Thrift.MessageType.CALL, requestId);
            const args: AuthenticateEmailArgs = new AuthenticateEmailArgs({ request, header });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_sendPiiVerificationCode(header: PHeader.TAuthHeader, request: Authenticate.TSendPiiVerificationCodeRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("sendPiiVerificationCode", thrift.Thrift.MessageType.CALL, requestId);
            const args: SendPiiVerificationCodeArgs = new SendPiiVerificationCodeArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_verifyWithPiiAndCode(header: PHeader.TAuthHeader, request: Authenticate.TVerifyWithPiiCodeRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("verifyWithPiiAndCode", thrift.Thrift.MessageType.CALL, requestId);
            const args: VerifyWithPiiAndCodeArgs = new VerifyWithPiiAndCodeArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_authenticateRater(request: Authenticate.TAuthenticateRaterRequest, header: PHeader.TUnauthHeader | undefined, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("authenticateRater", thrift.Thrift.MessageType.CALL, requestId);
            const args: AuthenticateRaterArgs = new AuthenticateRaterArgs({ request, header });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_sendEmailVerification(request: Authenticate.TSendEmailVerificationRequest, header: PHeader.TUnauthHeader | undefined, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("sendEmailVerification", thrift.Thrift.MessageType.CALL, requestId);
            const args: SendEmailVerificationArgs = new SendEmailVerificationArgs({ request, header });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_sendWebAuthRequest(request: Authenticate.TWebAuthenticateRequest, header: PHeader.TUnauthHeader | undefined, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("sendWebAuthRequest", thrift.Thrift.MessageType.CALL, requestId);
            const args: SendWebAuthRequestArgs = new SendWebAuthRequestArgs({ request, header });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_updateWebAuthRequest(header: PHeader.TAuthHeader, request: Authenticate.TWebAuthenticateUpdateRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("updateWebAuthRequest", thrift.Thrift.MessageType.CALL, requestId);
            const args: UpdateWebAuthRequestArgs = new UpdateWebAuthRequestArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_getActiveWebAuthRequest(header: PHeader.TAuthHeader, userId: Authenticate.TUserId, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("getActiveWebAuthRequest", thrift.Thrift.MessageType.CALL, requestId);
            const args: GetActiveWebAuthRequestArgs = new GetActiveWebAuthRequestArgs({ header, userId });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_requestInvite(request: Authenticate.TInviteRequest, header: PHeader.TUnauthHeader | undefined, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("requestInvite", thrift.Thrift.MessageType.CALL, requestId);
            const args: RequestInviteArgs = new RequestInviteArgs({ request, header });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_authenticateHeliosDashboardUser(request: Authenticate.TAuthenticateHeliosOrderDashboardLoginRequest, header: PHeader.TUnauthHeader | undefined, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("authenticateHeliosDashboardUser", thrift.Thrift.MessageType.CALL, requestId);
            const args: AuthenticateHeliosDashboardUserArgs = new AuthenticateHeliosDashboardUserArgs({ request, header });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_sendEmailSignInCode(header: PHeader.TUnauthHeader, request: Authenticate.TSendEmailSignInCodeRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("sendEmailSignInCode", thrift.Thrift.MessageType.CALL, requestId);
            const args: SendEmailSignInCodeArgs = new SendEmailSignInCodeArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_signInWithEmailAndCode(header: PHeader.TUnauthHeader, request: Authenticate.TSignInWithEmailAndCodeRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("signInWithEmailAndCode", thrift.Thrift.MessageType.CALL, requestId);
            const args: SignInWithEmailAndCodeArgs = new SignInWithEmailAndCodeArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_sendPhoneSignInCode(header: PHeader.TUnauthHeader, request: Authenticate.TSendPhoneSignInCodeRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("sendPhoneSignInCode", thrift.Thrift.MessageType.CALL, requestId);
            const args: SendPhoneSignInCodeArgs = new SendPhoneSignInCodeArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public send_signInWithPhoneAndCode(header: PHeader.TUnauthHeader, request: Authenticate.TSignInWithPhoneAndCodeRequest, requestId: number): void {
            const output: thrift.TProtocol = new this.protocol(this.output);
            output.writeMessageBegin("signInWithPhoneAndCode", thrift.Thrift.MessageType.CALL, requestId);
            const args: SignInWithPhoneAndCodeArgs = new SignInWithPhoneAndCodeArgs({ header, request });
            args.write(output);
            output.writeMessageEnd();
            this.output.flush();
            return;
        }
        public recv_authenticate(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: AuthenticateResult = AuthenticateResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else if (result.inviteError != null) {
                    return callback(result.inviteError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "authenticate failed: unknown result"));
                    }
                }
            }
        }
        public recv_createAndAuthenticateUser(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: CreateAndAuthenticateUserResult = CreateAndAuthenticateUserResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else if (result.inviteError != null) {
                    return callback(result.inviteError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "createAndAuthenticateUser failed: unknown result"));
                    }
                }
            }
        }
        public recv_refetchAccessToken(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: RefetchAccessTokenResult = RefetchAccessTokenResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "refetchAccessToken failed: unknown result"));
                    }
                }
            }
        }
        public recv_authenticateEmail(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: AuthenticateEmailResult = AuthenticateEmailResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "authenticateEmail failed: unknown result"));
                    }
                }
            }
        }
        public recv_sendPiiVerificationCode(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: SendPiiVerificationCodeResult = SendPiiVerificationCodeResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "sendPiiVerificationCode failed: unknown result"));
                    }
                }
            }
        }
        public recv_verifyWithPiiAndCode(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: VerifyWithPiiAndCodeResult = VerifyWithPiiAndCodeResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "verifyWithPiiAndCode failed: unknown result"));
                    }
                }
            }
        }
        public recv_authenticateRater(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: AuthenticateRaterResult = AuthenticateRaterResult.read(input);
                input.readMessageEnd();
                if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "authenticateRater failed: unknown result"));
                    }
                }
            }
        }
        public recv_sendEmailVerification(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: SendEmailVerificationResult = SendEmailVerificationResult.read(input);
                input.readMessageEnd();
                if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "sendEmailVerification failed: unknown result"));
                    }
                }
            }
        }
        public recv_sendWebAuthRequest(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: SendWebAuthRequestResult = SendWebAuthRequestResult.read(input);
                input.readMessageEnd();
                if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "sendWebAuthRequest failed: unknown result"));
                    }
                }
            }
        }
        public recv_updateWebAuthRequest(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: UpdateWebAuthRequestResult = UpdateWebAuthRequestResult.read(input);
                input.readMessageEnd();
                if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "updateWebAuthRequest failed: unknown result"));
                    }
                }
            }
        }
        public recv_getActiveWebAuthRequest(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: GetActiveWebAuthRequestResult = GetActiveWebAuthRequestResult.read(input);
                input.readMessageEnd();
                if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getActiveWebAuthRequest failed: unknown result"));
                    }
                }
            }
        }
        public recv_requestInvite(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: RequestInviteResult = RequestInviteResult.read(input);
                input.readMessageEnd();
                if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "requestInvite failed: unknown result"));
                    }
                }
            }
        }
        public recv_authenticateHeliosDashboardUser(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: AuthenticateHeliosDashboardUserResult = AuthenticateHeliosDashboardUserResult.read(input);
                input.readMessageEnd();
                if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "authenticateHeliosDashboardUser failed: unknown result"));
                    }
                }
            }
        }
        public recv_sendEmailSignInCode(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: SendEmailSignInCodeResult = SendEmailSignInCodeResult.read(input);
                input.readMessageEnd();
                if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "sendEmailSignInCode failed: unknown result"));
                    }
                }
            }
        }
        public recv_signInWithEmailAndCode(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: SignInWithEmailAndCodeResult = SignInWithEmailAndCodeResult.read(input);
                input.readMessageEnd();
                if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "signInWithEmailAndCode failed: unknown result"));
                    }
                }
            }
        }
        public recv_sendPhoneSignInCode(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: SendPhoneSignInCodeResult = SendPhoneSignInCodeResult.read(input);
                input.readMessageEnd();
                if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "sendPhoneSignInCode failed: unknown result"));
                    }
                }
            }
        }
        public recv_signInWithPhoneAndCode(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
            const noop = (): any => null;
            const callback = this._reqs[requestId] || noop;
            if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
                const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
                x.read(input);
                input.readMessageEnd();
                return callback(x);
            }
            else {
                const result: SignInWithPhoneAndCodeResult = SignInWithPhoneAndCodeResult.read(input);
                input.readMessageEnd();
                if (result.invalidRequestError != null) {
                    return callback(result.invalidRequestError);
                }
                else if (result.authFailedError != null) {
                    return callback(result.authFailedError);
                }
                else {
                    if (result.success != null) {
                        return callback(undefined, result.success);
                    }
                    else {
                        return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "signInWithPhoneAndCode failed: unknown result"));
                    }
                }
            }
        }
    }
    export interface IHandler {
        authenticate(request: Authenticate.TAuthenticateRequest, header?: PHeader.TUnauthHeader): Authenticate.TAuthenticateResponse | Promise<Authenticate.TAuthenticateResponse>;
        createAndAuthenticateUser(request: Authenticate.TCreateAndAuthenticateUserRequest, header?: PHeader.TUnauthHeader): Authenticate.TAuthenticateResponse | Promise<Authenticate.TAuthenticateResponse>;
        refetchAccessToken(request: Authenticate.TRefetchAccessTokenRequest, header?: PHeader.TUnauthHeader): Authenticate.TRefetchAccessTokenResponse | Promise<Authenticate.TRefetchAccessTokenResponse>;
        authenticateEmail(request: Authenticate.TAuthenticateEmailRequest, header?: PHeader.TUnauthHeader): Authenticate.TAuthenticateEmailResponse | Promise<Authenticate.TAuthenticateEmailResponse>;
        sendPiiVerificationCode(header: PHeader.TAuthHeader, request: Authenticate.TSendPiiVerificationCodeRequest): Authenticate.TSendPiiVerificationCodeResponse | Promise<Authenticate.TSendPiiVerificationCodeResponse>;
        verifyWithPiiAndCode(header: PHeader.TAuthHeader, request: Authenticate.TVerifyWithPiiCodeRequest): Authenticate.TVerifyWithPiiCodeResponse | Promise<Authenticate.TVerifyWithPiiCodeResponse>;
        authenticateRater(request: Authenticate.TAuthenticateRaterRequest, header?: PHeader.TUnauthHeader): Authenticate.TAuthenticateRaterResponse | Promise<Authenticate.TAuthenticateRaterResponse>;
        sendEmailVerification(request: Authenticate.TSendEmailVerificationRequest, header?: PHeader.TUnauthHeader): Authenticate.TSendEmailVerificationResponse | Promise<Authenticate.TSendEmailVerificationResponse>;
        sendWebAuthRequest(request: Authenticate.TWebAuthenticateRequest, header?: PHeader.TUnauthHeader): Authenticate.TWebAuthenticateStatusResponse | Promise<Authenticate.TWebAuthenticateStatusResponse>;
        updateWebAuthRequest(header: PHeader.TAuthHeader, request: Authenticate.TWebAuthenticateUpdateRequest): Authenticate.TWebAuthenticateUpdateResponse | Promise<Authenticate.TWebAuthenticateUpdateResponse>;
        getActiveWebAuthRequest(header: PHeader.TAuthHeader, userId: Authenticate.TUserId): Authenticate.TActiveWebAuthResponse | Promise<Authenticate.TActiveWebAuthResponse>;
        requestInvite(request: Authenticate.TInviteRequest, header?: PHeader.TUnauthHeader): Authenticate.TInviteResponse | Promise<Authenticate.TInviteResponse>;
        authenticateHeliosDashboardUser(request: Authenticate.TAuthenticateHeliosOrderDashboardLoginRequest, header?: PHeader.TUnauthHeader): Authenticate.TAuthenticateHeliosOrderDashboardLoginResponse | Promise<Authenticate.TAuthenticateHeliosOrderDashboardLoginResponse>;
        sendEmailSignInCode(header: PHeader.TUnauthHeader, request: Authenticate.TSendEmailSignInCodeRequest): Authenticate.TSendEmailSignInCodeResponse | Promise<Authenticate.TSendEmailSignInCodeResponse>;
        signInWithEmailAndCode(header: PHeader.TUnauthHeader, request: Authenticate.TSignInWithEmailAndCodeRequest): Authenticate.TSignInWithEmailAndCodeResponse | Promise<Authenticate.TSignInWithEmailAndCodeResponse>;
        sendPhoneSignInCode(header: PHeader.TUnauthHeader, request: Authenticate.TSendPhoneSignInCodeRequest): Authenticate.TSendPhoneSignInCodeResponse | Promise<Authenticate.TSendPhoneSignInCodeResponse>;
        signInWithPhoneAndCode(header: PHeader.TUnauthHeader, request: Authenticate.TSignInWithPhoneAndCodeRequest): Authenticate.TSignInWithPhoneAndCodeResponse | Promise<Authenticate.TSignInWithPhoneAndCodeResponse>;
    }
    export class Processor {
        public _handler: IHandler;
        constructor(handler: IHandler) {
            this._handler = handler;
        }
        public process(input: thrift.TProtocol, output: thrift.TProtocol): void {
            const metadata: thrift.TMessage = input.readMessageBegin();
            const fname: string = metadata.fname;
            const requestId: number = metadata.rseqid;
            const methodName: string = "process_" + fname;
            switch (methodName) {
                case "process_authenticate": {
                    this.process_authenticate(requestId, input, output);
                    return;
                }
                case "process_createAndAuthenticateUser": {
                    this.process_createAndAuthenticateUser(requestId, input, output);
                    return;
                }
                case "process_refetchAccessToken": {
                    this.process_refetchAccessToken(requestId, input, output);
                    return;
                }
                case "process_authenticateEmail": {
                    this.process_authenticateEmail(requestId, input, output);
                    return;
                }
                case "process_sendPiiVerificationCode": {
                    this.process_sendPiiVerificationCode(requestId, input, output);
                    return;
                }
                case "process_verifyWithPiiAndCode": {
                    this.process_verifyWithPiiAndCode(requestId, input, output);
                    return;
                }
                case "process_authenticateRater": {
                    this.process_authenticateRater(requestId, input, output);
                    return;
                }
                case "process_sendEmailVerification": {
                    this.process_sendEmailVerification(requestId, input, output);
                    return;
                }
                case "process_sendWebAuthRequest": {
                    this.process_sendWebAuthRequest(requestId, input, output);
                    return;
                }
                case "process_updateWebAuthRequest": {
                    this.process_updateWebAuthRequest(requestId, input, output);
                    return;
                }
                case "process_getActiveWebAuthRequest": {
                    this.process_getActiveWebAuthRequest(requestId, input, output);
                    return;
                }
                case "process_requestInvite": {
                    this.process_requestInvite(requestId, input, output);
                    return;
                }
                case "process_authenticateHeliosDashboardUser": {
                    this.process_authenticateHeliosDashboardUser(requestId, input, output);
                    return;
                }
                case "process_sendEmailSignInCode": {
                    this.process_sendEmailSignInCode(requestId, input, output);
                    return;
                }
                case "process_signInWithEmailAndCode": {
                    this.process_signInWithEmailAndCode(requestId, input, output);
                    return;
                }
                case "process_sendPhoneSignInCode": {
                    this.process_sendPhoneSignInCode(requestId, input, output);
                    return;
                }
                case "process_signInWithPhoneAndCode": {
                    this.process_signInWithPhoneAndCode(requestId, input, output);
                    return;
                }
                default: {
                    input.skip(thrift.Thrift.Type.STRUCT);
                    input.readMessageEnd();
                    const errMessage = "Unknown function " + fname;
                    const err = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN_METHOD, errMessage);
                    output.writeMessageBegin(fname, thrift.Thrift.MessageType.EXCEPTION, requestId);
                    err.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            }
        }
        public process_authenticate(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<Authenticate.TAuthenticateResponse>((resolve, reject): void => {
                try {
                    const args: AuthenticateArgs = AuthenticateArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.authenticate(args.request, args.header));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: Authenticate.TAuthenticateResponse): void => {
                const result: AuthenticateResult = new AuthenticateResult({ success: data });
                output.writeMessageBegin("authenticate", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: AuthenticateResult = new AuthenticateResult({ authFailedError: err });
                    output.writeMessageBegin("authenticate", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: AuthenticateResult = new AuthenticateResult({ invalidRequestError: err });
                    output.writeMessageBegin("authenticate", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInviteException) {
                    const result: AuthenticateResult = new AuthenticateResult({ inviteError: err });
                    output.writeMessageBegin("authenticate", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("authenticate", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_createAndAuthenticateUser(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<Authenticate.TAuthenticateResponse>((resolve, reject): void => {
                try {
                    const args: CreateAndAuthenticateUserArgs = CreateAndAuthenticateUserArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.createAndAuthenticateUser(args.request, args.header));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: Authenticate.TAuthenticateResponse): void => {
                const result: CreateAndAuthenticateUserResult = new CreateAndAuthenticateUserResult({ success: data });
                output.writeMessageBegin("createAndAuthenticateUser", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: CreateAndAuthenticateUserResult = new CreateAndAuthenticateUserResult({ authFailedError: err });
                    output.writeMessageBegin("createAndAuthenticateUser", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: CreateAndAuthenticateUserResult = new CreateAndAuthenticateUserResult({ invalidRequestError: err });
                    output.writeMessageBegin("createAndAuthenticateUser", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInviteException) {
                    const result: CreateAndAuthenticateUserResult = new CreateAndAuthenticateUserResult({ inviteError: err });
                    output.writeMessageBegin("createAndAuthenticateUser", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("createAndAuthenticateUser", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_refetchAccessToken(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<Authenticate.TRefetchAccessTokenResponse>((resolve, reject): void => {
                try {
                    const args: RefetchAccessTokenArgs = RefetchAccessTokenArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.refetchAccessToken(args.request, args.header));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: Authenticate.TRefetchAccessTokenResponse): void => {
                const result: RefetchAccessTokenResult = new RefetchAccessTokenResult({ success: data });
                output.writeMessageBegin("refetchAccessToken", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: RefetchAccessTokenResult = new RefetchAccessTokenResult({ authFailedError: err });
                    output.writeMessageBegin("refetchAccessToken", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: RefetchAccessTokenResult = new RefetchAccessTokenResult({ invalidRequestError: err });
                    output.writeMessageBegin("refetchAccessToken", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("refetchAccessToken", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_authenticateEmail(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<Authenticate.TAuthenticateEmailResponse>((resolve, reject): void => {
                try {
                    const args: AuthenticateEmailArgs = AuthenticateEmailArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.authenticateEmail(args.request, args.header));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: Authenticate.TAuthenticateEmailResponse): void => {
                const result: AuthenticateEmailResult = new AuthenticateEmailResult({ success: data });
                output.writeMessageBegin("authenticateEmail", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: AuthenticateEmailResult = new AuthenticateEmailResult({ authFailedError: err });
                    output.writeMessageBegin("authenticateEmail", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: AuthenticateEmailResult = new AuthenticateEmailResult({ invalidRequestError: err });
                    output.writeMessageBegin("authenticateEmail", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("authenticateEmail", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_sendPiiVerificationCode(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<Authenticate.TSendPiiVerificationCodeResponse>((resolve, reject): void => {
                try {
                    const args: SendPiiVerificationCodeArgs = SendPiiVerificationCodeArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.sendPiiVerificationCode(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: Authenticate.TSendPiiVerificationCodeResponse): void => {
                const result: SendPiiVerificationCodeResult = new SendPiiVerificationCodeResult({ success: data });
                output.writeMessageBegin("sendPiiVerificationCode", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: SendPiiVerificationCodeResult = new SendPiiVerificationCodeResult({ authFailedError: err });
                    output.writeMessageBegin("sendPiiVerificationCode", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: SendPiiVerificationCodeResult = new SendPiiVerificationCodeResult({ invalidRequestError: err });
                    output.writeMessageBegin("sendPiiVerificationCode", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("sendPiiVerificationCode", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_verifyWithPiiAndCode(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<Authenticate.TVerifyWithPiiCodeResponse>((resolve, reject): void => {
                try {
                    const args: VerifyWithPiiAndCodeArgs = VerifyWithPiiAndCodeArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.verifyWithPiiAndCode(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: Authenticate.TVerifyWithPiiCodeResponse): void => {
                const result: VerifyWithPiiAndCodeResult = new VerifyWithPiiAndCodeResult({ success: data });
                output.writeMessageBegin("verifyWithPiiAndCode", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: VerifyWithPiiAndCodeResult = new VerifyWithPiiAndCodeResult({ authFailedError: err });
                    output.writeMessageBegin("verifyWithPiiAndCode", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: VerifyWithPiiAndCodeResult = new VerifyWithPiiAndCodeResult({ invalidRequestError: err });
                    output.writeMessageBegin("verifyWithPiiAndCode", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("verifyWithPiiAndCode", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_authenticateRater(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<Authenticate.TAuthenticateRaterResponse>((resolve, reject): void => {
                try {
                    const args: AuthenticateRaterArgs = AuthenticateRaterArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.authenticateRater(args.request, args.header));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: Authenticate.TAuthenticateRaterResponse): void => {
                const result: AuthenticateRaterResult = new AuthenticateRaterResult({ success: data });
                output.writeMessageBegin("authenticateRater", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: AuthenticateRaterResult = new AuthenticateRaterResult({ authFailedError: err });
                    output.writeMessageBegin("authenticateRater", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: AuthenticateRaterResult = new AuthenticateRaterResult({ invalidRequestError: err });
                    output.writeMessageBegin("authenticateRater", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("authenticateRater", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_sendEmailVerification(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<Authenticate.TSendEmailVerificationResponse>((resolve, reject): void => {
                try {
                    const args: SendEmailVerificationArgs = SendEmailVerificationArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.sendEmailVerification(args.request, args.header));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: Authenticate.TSendEmailVerificationResponse): void => {
                const result: SendEmailVerificationResult = new SendEmailVerificationResult({ success: data });
                output.writeMessageBegin("sendEmailVerification", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: SendEmailVerificationResult = new SendEmailVerificationResult({ invalidRequestError: err });
                    output.writeMessageBegin("sendEmailVerification", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("sendEmailVerification", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_sendWebAuthRequest(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<Authenticate.TWebAuthenticateStatusResponse>((resolve, reject): void => {
                try {
                    const args: SendWebAuthRequestArgs = SendWebAuthRequestArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.sendWebAuthRequest(args.request, args.header));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: Authenticate.TWebAuthenticateStatusResponse): void => {
                const result: SendWebAuthRequestResult = new SendWebAuthRequestResult({ success: data });
                output.writeMessageBegin("sendWebAuthRequest", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: SendWebAuthRequestResult = new SendWebAuthRequestResult({ invalidRequestError: err });
                    output.writeMessageBegin("sendWebAuthRequest", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("sendWebAuthRequest", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_updateWebAuthRequest(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<Authenticate.TWebAuthenticateUpdateResponse>((resolve, reject): void => {
                try {
                    const args: UpdateWebAuthRequestArgs = UpdateWebAuthRequestArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.updateWebAuthRequest(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: Authenticate.TWebAuthenticateUpdateResponse): void => {
                const result: UpdateWebAuthRequestResult = new UpdateWebAuthRequestResult({ success: data });
                output.writeMessageBegin("updateWebAuthRequest", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: UpdateWebAuthRequestResult = new UpdateWebAuthRequestResult({ invalidRequestError: err });
                    output.writeMessageBegin("updateWebAuthRequest", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("updateWebAuthRequest", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_getActiveWebAuthRequest(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<Authenticate.TActiveWebAuthResponse>((resolve, reject): void => {
                try {
                    const args: GetActiveWebAuthRequestArgs = GetActiveWebAuthRequestArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.getActiveWebAuthRequest(args.header, args.userId));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: Authenticate.TActiveWebAuthResponse): void => {
                const result: GetActiveWebAuthRequestResult = new GetActiveWebAuthRequestResult({ success: data });
                output.writeMessageBegin("getActiveWebAuthRequest", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: GetActiveWebAuthRequestResult = new GetActiveWebAuthRequestResult({ invalidRequestError: err });
                    output.writeMessageBegin("getActiveWebAuthRequest", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("getActiveWebAuthRequest", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_requestInvite(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<Authenticate.TInviteResponse>((resolve, reject): void => {
                try {
                    const args: RequestInviteArgs = RequestInviteArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.requestInvite(args.request, args.header));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: Authenticate.TInviteResponse): void => {
                const result: RequestInviteResult = new RequestInviteResult({ success: data });
                output.writeMessageBegin("requestInvite", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: RequestInviteResult = new RequestInviteResult({ invalidRequestError: err });
                    output.writeMessageBegin("requestInvite", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("requestInvite", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_authenticateHeliosDashboardUser(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<Authenticate.TAuthenticateHeliosOrderDashboardLoginResponse>((resolve, reject): void => {
                try {
                    const args: AuthenticateHeliosDashboardUserArgs = AuthenticateHeliosDashboardUserArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.authenticateHeliosDashboardUser(args.request, args.header));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: Authenticate.TAuthenticateHeliosOrderDashboardLoginResponse): void => {
                const result: AuthenticateHeliosDashboardUserResult = new AuthenticateHeliosDashboardUserResult({ success: data });
                output.writeMessageBegin("authenticateHeliosDashboardUser", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: AuthenticateHeliosDashboardUserResult = new AuthenticateHeliosDashboardUserResult({ invalidRequestError: err });
                    output.writeMessageBegin("authenticateHeliosDashboardUser", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: AuthenticateHeliosDashboardUserResult = new AuthenticateHeliosDashboardUserResult({ authFailedError: err });
                    output.writeMessageBegin("authenticateHeliosDashboardUser", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("authenticateHeliosDashboardUser", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_sendEmailSignInCode(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<Authenticate.TSendEmailSignInCodeResponse>((resolve, reject): void => {
                try {
                    const args: SendEmailSignInCodeArgs = SendEmailSignInCodeArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.sendEmailSignInCode(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: Authenticate.TSendEmailSignInCodeResponse): void => {
                const result: SendEmailSignInCodeResult = new SendEmailSignInCodeResult({ success: data });
                output.writeMessageBegin("sendEmailSignInCode", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: SendEmailSignInCodeResult = new SendEmailSignInCodeResult({ invalidRequestError: err });
                    output.writeMessageBegin("sendEmailSignInCode", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("sendEmailSignInCode", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_signInWithEmailAndCode(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<Authenticate.TSignInWithEmailAndCodeResponse>((resolve, reject): void => {
                try {
                    const args: SignInWithEmailAndCodeArgs = SignInWithEmailAndCodeArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.signInWithEmailAndCode(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: Authenticate.TSignInWithEmailAndCodeResponse): void => {
                const result: SignInWithEmailAndCodeResult = new SignInWithEmailAndCodeResult({ success: data });
                output.writeMessageBegin("signInWithEmailAndCode", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: SignInWithEmailAndCodeResult = new SignInWithEmailAndCodeResult({ invalidRequestError: err });
                    output.writeMessageBegin("signInWithEmailAndCode", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: SignInWithEmailAndCodeResult = new SignInWithEmailAndCodeResult({ authFailedError: err });
                    output.writeMessageBegin("signInWithEmailAndCode", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("signInWithEmailAndCode", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_sendPhoneSignInCode(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<Authenticate.TSendPhoneSignInCodeResponse>((resolve, reject): void => {
                try {
                    const args: SendPhoneSignInCodeArgs = SendPhoneSignInCodeArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.sendPhoneSignInCode(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: Authenticate.TSendPhoneSignInCodeResponse): void => {
                const result: SendPhoneSignInCodeResult = new SendPhoneSignInCodeResult({ success: data });
                output.writeMessageBegin("sendPhoneSignInCode", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: SendPhoneSignInCodeResult = new SendPhoneSignInCodeResult({ invalidRequestError: err });
                    output.writeMessageBegin("sendPhoneSignInCode", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("sendPhoneSignInCode", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
        public process_signInWithPhoneAndCode(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
            new Promise<Authenticate.TSignInWithPhoneAndCodeResponse>((resolve, reject): void => {
                try {
                    const args: SignInWithPhoneAndCodeArgs = SignInWithPhoneAndCodeArgs.read(input);
                    input.readMessageEnd();
                    resolve(this._handler.signInWithPhoneAndCode(args.header, args.request));
                }
                catch (err) {
                    reject(err);
                }
            }).then((data: Authenticate.TSignInWithPhoneAndCodeResponse): void => {
                const result: SignInWithPhoneAndCodeResult = new SignInWithPhoneAndCodeResult({ success: data });
                output.writeMessageBegin("signInWithPhoneAndCode", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }).catch((err: Error): void => {
                if (err instanceof GenericExceptions.TInvalidRequest) {
                    const result: SignInWithPhoneAndCodeResult = new SignInWithPhoneAndCodeResult({ invalidRequestError: err });
                    output.writeMessageBegin("signInWithPhoneAndCode", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else if (err instanceof GenericExceptions.TAuthFailed) {
                    const result: SignInWithPhoneAndCodeResult = new SignInWithPhoneAndCodeResult({ authFailedError: err });
                    output.writeMessageBegin("signInWithPhoneAndCode", thrift.Thrift.MessageType.REPLY, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
                else {
                    const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                    output.writeMessageBegin("signInWithPhoneAndCode", thrift.Thrift.MessageType.EXCEPTION, requestId);
                    result.write(output);
                    output.writeMessageEnd();
                    output.flush();
                    return;
                }
            });
        }
    }
}
